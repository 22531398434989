/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppLoadService } from '@app/app-load.service';
import { SignalRStatusService } from '@app/notifications/shared/handlers/signalr-status-service';
import { EquipmentVariable } from '@app/map/equipments/equipment-variable';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { ImmsService } from '@app/shared/services/imms.service';
import { ImageSecureService } from '@app/shared/services/image-secure.service';
import { ErrorService } from '@ays';
import { Level, Metadata, VARIABLE_EMPTY_REQUEST_ID, VariableFilter, VariableFilters, VariableNotification, WILD_CARD_CHARACTER } from '@app/notifications/shared/events/variable-status';
import { AysCommonsModule, InputComponent,  } from '@ays';
import { SelectComponent } from '@ays/lib/components/forms/select/select.component';
import { OffcanvasComponent } from '@ays/lib/components/offcanvas/offcanvas.component';
import { ModalConfirmationComponent } from '@ays/commons/lib/components/modal-confirmation/modal-confirmation.component'
import { isActiveVariable, isRunVariable, VariableColors, VariableType } from '@variables/shared/variable';
import { Many, ObjectIteratee, ObjectIterator } from 'lodash';
import { forkJoin, from, of } from 'rxjs';
import { concatMap, delay, map, take } from 'rxjs/operators';
//import { PageLifecycleService } from '@app/shared/services/page-lifecycle.service';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SafeUrl } from '@angular/platform-browser';
import { TextColorPipe } from '@app/shared/pipes/text-color.pipe';
import { VariablesService } from '@variables/shared/variables.service';
import * as _ from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { AnalyticsPanelComponent } from '../analytics-panel/analytics-panel.component';
import { InputComponent2 } from '../forms/input/input.component';
import { LoadingSpinnerComponent } from '../forms/loading-spinner/loading-spinner.component';
import { SelectVirtualComponent } from '../forms/select-virtual/select-virtual.component';
import { LineParameterComponent } from './components/line-parameter/line-parameter.component';
import { SignalREvents } from '@app/notifications/shared/clients/signalr-status.client';

enum SortField {
    NAME = 'name',
    TYPE = 'type',
    ACTIVE = 'active',
}
enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}
const { NAME, TYPE, ACTIVE } = SortField;
const { ASC, DESC } = SortOrder;
const FAILED = 'Failed';

enum TABS {
    variables = 'variables',
    analytics = 'analytics',
    equipmentView = 'equipmentView',
}

interface ImmsWorkOrderToast {
    uuid: string;
    title: string;
    code: number;
    msg: string;
}

@Component({
    selector: 'shared-lines-data-modal',
    templateUrl: './lines-data-modal.component.html',
    styleUrls: ['./lines-data-modal.component.scss'],
    providers: [TextColorPipe],
    standalone: true,
    imports: [
        AysCommonsModule,
        SelectVirtualComponent,
        InputComponent2,
        NgIf,
        LoadingSpinnerComponent,
        NgClass,
        NgFor,
        LineParameterComponent,
        AnalyticsPanelComponent
    ],
})
export class LinesDataModalComponent implements OnInit, AfterViewInit, OnDestroy /*, OnChanges*/ {
    @Input() lines;
    @Input() modalId;
    @Input() equipments: Array<string>;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onHide = new EventEmitter();
    @ViewChild('offcanvas', { static: true }) offcanvas: OffcanvasComponent;
    @ViewChild('linesSelector', { static: true }) linesSelector: SelectComponent;
    @ViewChild('equipmentsSelector', { static: true }) equipmentsSelector: SelectComponent;
    @ViewChild('equipmentsSearch', { static: true }) equipmentsSearch: InputComponent;
    @ViewChild('variableSearch', { static: true }) variableSearch: InputComponent;
    @ViewChild('variableTypeSelector', { static: true }) variableTypeSelector: SelectComponent;
    @ViewChild('equipmentsSearch2') equipmentsSearch2: InputComponent2;
    @ViewChild('workOrderConfirmationModal', { static: false }) workOrderConfirmationModal: ModalConfirmationComponent;
    @ViewChild('svg', { read: ElementRef, static: true }) svg;
    $svg: JQuery;
    $linesSelector: JQuery;
    $equipmentsSelector: JQuery;
    $equipmentsSearch: JQuery;
    $variableSearch: JQuery;
    $variableTypeSelector: JQuery;
    $equipmentsSearch2: JQuery;
    selectedValue = ' ';
    equipmentVariables: Array<any> = [];
    currentLine: string;
    lastLine: string;
    lineEquipments: Array<any> = [];
    variableTypes: Array<string> = [];
    kpis: any;
    lineIds = [];
    equipmentIds = [];
    isLoading = false;
    isLoadingAnalytics = false;
    isLoadingEquipmentView = false;
    showWarningMessage = false;
    selectedTab: TABS = TABS.variables;
    currentRequestId: string;
    currentFilters: VariableFilters;
    subscribed = false;
    sortProperty: SortField = ACTIVE;
    sortOrder: boolean | SortOrder = ASC;
    onlyShowActive = true;
    variableNameFilter: string;
    // fqnPrefix: string; //W.F.A.Z.EQL.E.ET
    fqnPrefixForOpc: string; //W.F.A.Z.EQL.ET.E
    selectedType = '';
    isActiveVariable = isActiveVariable;
    isLineView = false;
    isMobile = false;
    ASC = ASC;
    DESC = DESC;
    NAME = NAME;
    TYPE = TYPE;
    ACTIVE = ACTIVE;
    lineFqdn = '';
    equipmentSearch = '';

    setViewBox = '0 0 600 600';
    backgroundImage: string | SafeUrl = '';
    metadata: Metadata;

    isOpen = false;
    isWaitingForWorkOrderResponse = false;
    showConsole = false;
    refreshingAll = false;
    refreshingVariables: Array<string> = [];
    refreshErrors: Array<string> = [];
    confirmationMessage = {
        equipment: '',
    };
    immsWorkOrderToasts: ImmsWorkOrderToast[] = [{uuid: null, title: null, code: 0, msg: null}]

    variableColorsConfiguration: VariableColors[] = [];
    openModalWithVariablesRT: () => void;
    closeModalWithVariablesRT: () => void;
    destroyRef = inject(DestroyRef);

    private readonly renderer2 = inject(Renderer2);
    private readonly equipmentsService = inject(EquipmentsService);
    private readonly appLoadService = inject(AppLoadService);
    private readonly immsService = inject(ImmsService);
    private readonly router = inject(Router);
    private readonly errorService = inject(ErrorService);
    //private pageLifeCycleService: PageLifecycleService,
    private readonly signalRService = inject(SignalRStatusService);
    private readonly variableService = inject(VariablesService);
    private readonly textColorPipe = inject(TextColorPipe);
    private readonly imageSecureService = inject(ImageSecureService);

    constructor() {
        this.isMobile = window.matchMedia('screen and (max-width:991px)').matches;
        $(window).on('resize.lineDataModal', () => {
            this.isMobile = window.matchMedia('screen and (max-width:991px)').matches;
        });
    }

    ngOnInit() {
        this.currentLine = '';
    }

    ngAfterViewInit(): void {
        const that = this;
        this.$equipmentsSearch2 = this.equipmentsSearch2.$input;
        this.$equipmentsSearch2.off('keyup.sendEquipment').on('keyup.sendEquipment', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                this.navigateToEquipment($(e.target).val());
            }
        });
        $(() => {
            this.$linesSelector = this.linesSelector.$select;
            this.$linesSelector.on('change', function (e) {
                that.disableAllControls();
                that.$equipmentsSearch.val('');
                that.$variableSearch.val('');
                $('.results-lines').hide();
                $('.results-lines-last24').hide();
                that.isLoading = false;
                that.isLoadingAnalytics = false;
                const value = String($(this).val());
                if (value.length > 10) {
                    that.lineIds = value.split('-');
                    that.equipmentsService
                        .getEquipmentNamesByLine(that.lineIds[1], that.lineIds[2], that.lineIds[3], that.lineIds[4])
                        .pipe(takeUntilDestroyed(that.destroyRef))
                        .subscribe((res) => {
                            that.lineEquipments = res;
                            that.currentLine = null;
                            that.enableAllControls();
                        });
                } else {
                    that.lineEquipments = [];
                    that.currentLine = null;
                    that.enableAllControls();
                }
            });
            const pattern = /^\/warehouse\/.{1,}\/floor\/.{1,}\/area\/.{1,}\/zone\/.{1,}\/line\/.{1,}$/;
            this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
                if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                    this.offcanvas.hideDetails();
                    this.isLineView = pattern.test(event.url);
                    if (this.isLineView) {
                        const params = event.url.split('/');
                        //params[10].split(';')[0] maybe you receive  something like... ../line/EQLMUSTANG_2_AXIS_X;equipment=MUSTANG_2_AXIS_X_MAIN_TRAVEL
                        const line = String(`${params[2]}-${params[4]}-${params[6]}-${params[8]}-${params[10].split(';')[0]}`);
                        this.selectedValue = line;
                    } else {
                        this.selectedValue = ' ';
                    }
                }
            });
            this.$equipmentsSelector = this.equipmentsSelector.$select;
            this.$equipmentsSelector.on('change', (e) => {
                that.disableAllControls();
                that.$equipmentsSearch.val('');
                that.$variableSearch.val('');
                $('.results-lines').hide();
                $('.results-lines-last24').hide();
                that.isLoading = true;
                that.isLoadingAnalytics = false;
                const value = String($(e.target).val());
                that.currentLine = value;
                that.equipmentIds = value.split('-');
                // tslint:disable-next-line:max-line-length
                that.equipmentsService
                    .getVariablesFromEquipment(that.lineIds[1], that.lineIds[2], that.lineIds[3], that.lineIds[4], that.equipmentIds[0], that.equipmentIds[1])
                    .pipe(takeUntilDestroyed(that.destroyRef))
                    .subscribe((variables) => {
                        that.setVariables(variables);
                        this.isLoading = false;
                        this.enableAllControls();
                        this.loadVariableRT();
                        if ($('a[data-bs-toggle="tab"].equipment-tab.active').text() === 'Analytics') {
                            this.getKpisFromEquipment();
                        }
                        //this.fqnPrefix = `${this.lineIds[0]}.${this.lineIds[1]}.${this.lineIds[2]}.${this.lineIds[3]}.${this.lineIds[4]}.${this.equipmentIds[0]}.${this.equipmentIds[1]}.`;
                        if (that.selectedTab === TABS.equipmentView) {
                            that.showEquipmentView(that.equipmentIds[1]);
                        }

                        const lineId = this.lineIds.join('.');
                        // const equipmentId = this.equipmentIds.join('.');
                        // this.fqnPrefix = `${lineId}.${equipmentId}`;
                        this.fqnPrefixForOpc = `${lineId}.${this.equipmentIds[1]}.${this.equipmentIds[0]}`;
                    });
            });
            this.appLoadService.getEquipmentVariables.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
                if (res.equipment && res.type) {
                    const equipment = `${res.equipment}-${res.type}`;
                    this.$linesSelector.trigger('change');
                    this.offcanvas.showDetails();
                    setTimeout(() => {
                        this.$equipmentsSelector.val(equipment).trigger('change');
                    }, 200);

                    if (this.selectedTab === TABS.equipmentView) {
                        this.showEquipmentView(res.type);
                    }
                } else {
                    if (res.equipment) {
                        this.$equipmentsSearch.val(res.equipment).trigger('blur');
                        this.getVariablesFromEquipment(res.equipment);
                        this.offcanvas.showDetails();
                    }
                }
            });
            this.$variableTypeSelector = this.variableTypeSelector.$select;
            this.$variableTypeSelector.on('change', (event) => {
                const selectedType = String($(event.target).val());
                this.selectedType = selectedType !== 'All' ? selectedType : '';
            });
        });
        this.$equipmentsSearch = this.equipmentsSearch.$input;
        this.$equipmentsSearch.off('keyup.sendEquipment').on('keyup.sendEquipment', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                this.getVariablesFromEquipment($(e.target).val());
            }
        });
        this.$variableSearch = this.variableSearch.$input;
        this.$variableSearch.off('keyup').on('keyup', (e) => {
            this.variableNameFilter = String($(e.target).val());
        });
        this.$svg = $(this.svg.nativeElement);

        this.openModalWithVariablesRT = () => {
            this.isOpen = true;
            if (this.currentLine === this.lastLine) {
                this.loadVariableRT();
            }
        };

        this.closeModalWithVariablesRT = async () => {
            this.isOpen = false;
            if (this.subscribed) {
                this.signalRService.endSingleSubscription(this.currentFilters, [SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED]);
                this.subscribed = false;
                this.lastLine = this.currentLine;
            }
        };

        const myOffcanvasEquipments = document.getElementById('offcanvasEquipments');
        myOffcanvasEquipments.addEventListener('show.bs.offcanvas', this.openModalWithVariablesRT);
        myOffcanvasEquipments.addEventListener('hide.bs.offcanvas', this.closeModalWithVariablesRT);
        $('#workOrderConfirmationModal > div > div > div.modal-footer').css('border-top', 'none');

        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ prevState, state }) => {
        //     if (
        //         this.isOpen &&
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.variablesActiveStatusService.isConnected()
        //     ) {
        //         this.handleReconnection();
        //     }
        // });

        this.signalRService.getOnReconnectSubject().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            if (this.isOpen) {
                this.handleReconnection();
            }
        });

        this.appLoadService.getGlobalConfiguration.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
            this.variableColorsConfiguration = res['variableColors'] ?? [];
        });
    }

    openModal() {
        this.offcanvas.showDetails();
    }
    closeModal() {
        this.offcanvas.hideDetails();
    }

    getKpisFromEquipment() {
        this.disableAllControls();
        this.isLoadingAnalytics = true;
        const [__, floorId, areaId, zoneId, lineId] = this.lineIds;
        const [equipmentId] = this.equipmentIds;
        this.equipmentsService
            .getKpisFromEquipment(floorId, areaId, zoneId, lineId, equipmentId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((kpis) => {
                this.kpis = kpis;
                this.isLoadingAnalytics = false;
                this.enableAllControls();
            });
    }

    async ngOnDestroy() {
        this.subscribed = false;

        const myOffcanvasEquipments = document.getElementById('offcanvasEquipments');
        myOffcanvasEquipments.removeEventListener('show.bs.offcanvas', this.openModalWithVariablesRT);
        myOffcanvasEquipments.removeEventListener('hide.bs.offcanvas', this.closeModalWithVariablesRT);

        this.currentRequestId = 'stop-processing-notifications';
        this.signalRService.endSingleSubscription(this.currentFilters, [SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED]);
        this.$svg?.remove();
        this.$svg = null;

        $(window).off('resize.lineDataModal');

        this.equipmentVariables = null;
        this.lineEquipments = null;
        this.lineIds = null;
        this.equipmentIds = null;
        this.kpis = null;
        this.lines = null;
        this.$linesSelector?.off();
        this.$equipmentsSelector?.off();
        this.$variableTypeSelector?.off();
        this.$equipmentsSearch?.off();
        this.$variableSearch?.off();
    }

    getVariablesFromEquipment(equipment) {
        this.disableAllControls();
        $('.results-lines').hide();
        $('.results-lines-last24').hide();
        this.isLoading = true;
        this.isLoadingAnalytics = false;
        const equipmentSearch = String(equipment);
        this.currentLine = equipmentSearch;
        this.equipmentsService
            .getEquipmentLocation(equipmentSearch)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((res) => {
                this.lineEquipments = [];
                if (res) {
                    this.lineIds[0] = res.warehouseId;
                    this.lineIds[1] = res.floorId;
                    this.lineIds[2] = res.areaId;
                    this.lineIds[3] = res.zoneId;
                    this.lineIds[4] = res.lineId;
                    this.equipmentIds[0] = res.id;
                    this.equipmentIds[1] = res.type;
                    // tslint:disable-next-line:max-line-length
                    this.equipmentsService
                        .getVariablesFromEquipment(
                            this.lineIds[1],
                            this.lineIds[2],
                            this.lineIds[3],
                            this.lineIds[4],
                            this.equipmentIds[0],
                            this.equipmentIds[1],
                        )
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe((variables) => {
                            this.setVariables(variables);
                            this.isLoading = false;
                            this.enableAllControls();
                            this.loadVariableRT();
                            if ($('a[data-bs-toggle="tab"].equipment-tab.active').text() === 'Analytics') {
                                this.getKpisFromEquipment();
                            }
                            const lineId = this.lineIds.join('.');
                            // const equipmentId = this.equipmentIds.join('.');
                            // this.fqnPrefix = `${lineId}.${equipmentId}`;
                            this.fqnPrefixForOpc = `${lineId}.${this.equipmentIds[1]}.${this.equipmentIds[0]}`;
                        });
                } else {
                    $('.toast').toast({ delay: 5000 });
                    $('.toast').toast('show');
                    this.enableAllControls();
                }
            });
    }

    reloadData() {
        this.disableAllControls();
        $('.results-lines').hide();
        this.isLoading = true;
        this.isLoadingAnalytics = false;
        const [__, floorId, areaId, zoneId, lineId] = this.lineIds;
        const [equipmentId, equipmentType] = this.equipmentIds;
        this.equipmentsService
            .getVariablesFromEquipment(floorId, areaId, zoneId, lineId, equipmentId, equipmentType)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((variables) => {
                this.setVariables(variables);
                this.isLoading = false;
                this.enableAllControls();
            });
    }

    forceRefreshVariables() {
        this.refreshingAll = true;
        from(this.equipmentVariables)
            .pipe(
                concatMap((variable) => {
                    const fqn = `${this.fqnPrefixForOpc}.${variable.type}.${variable.name}`.toUpperCase();

                    const opcFQN = `${this.fqnPrefixForOpc.replaceAll('.', '-')}-${variable.type.toUpperCase().replace('ALARM', 'FAILURE')}-${variable.name}`.toUpperCase();

                    this.refreshingVariables = [...this.refreshingVariables, fqn];
                    return forkJoin([of(fqn), this.variableService.readVariable(opcFQN).pipe(take(1), delay(1000))]);
                }),
            )
            .pipe(
                map(([fqn, response]) => {
                    if (FAILED === response.status) {
                        this.refreshErrors = [...this.refreshErrors, fqn];
                        setTimeout(() => {
                            this.refreshErrors = this.refreshErrors.filter((f) => fqn !== f);
                            this.refreshingVariables = this.refreshingVariables.filter((f) => f !== fqn);
                        }, 5000);
                    }
                    return fqn;
                }),
            )
            .subscribe(
                {
                    next: (fqn) => {
                        this.refreshingVariables = this.refreshingVariables.filter((f) => f !== fqn);
                    },
                    error: (err) => console.error(err),
                    complete: () => {
                        this.refreshingAll = false;
                    }
                }
            );
    }

    disableAllControls() {
        this.$linesSelector.prop('disabled', true);
        this.$equipmentsSelector.prop('disabled', true);
        this.$equipmentsSearch.prop('disabled', true);
        this.$variableSearch.prop('disabled', true);
        this.$variableTypeSelector.prop('disabled', true);
    }
    enableAllControls() {
        this.$linesSelector.prop('disabled', false);
        this.$equipmentsSelector.prop('disabled', false);
        this.$equipmentsSearch.prop('disabled', false);
        this.$variableSearch.prop('disabled', false);
        this.$variableTypeSelector.prop('disabled', false);
    }

    trackByName(index: number, variable: { name: string }): string {
        return variable.name;
    }

    async loadVariableRT() {
        if (!this.isOpen) return;

        const currentRequestId = 'ModalVariable---' + uuid();
        const [warehouseId, floorId, areaId, zoneId, lineId] = this.lineIds;
        const [equipmentId, equipmentType] = this.equipmentIds;
        const groupName = `${warehouseId}-${floorId}-${areaId}-${zoneId}-${lineId}-${equipmentId}`;
        const filter: VariableFilter = {
            warehouseId,
            floorId,
            areaId,
            zoneId,
            lineId,
            equipmentId,
            equipmentType,
            maxNotificationItems: this.equipmentVariables.length,
        };

        const filters = new VariableFilters({
            requestId: currentRequestId,
            filters: [filter],
            maxNotificationItems: this.equipmentVariables.length,
            orderColumn: 'variableSourceTimestamp',
            orderType: 'desc',
        });

        if (!this.subscribed) {
            if (this.isOpen) {
                this.signalRService.startSingleSubscription(filters, [SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED])
                    .then((subjects) => {
                        subjects(filters, SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED)
                            .subscribe({
                                next: notification => this.receiveRTNotification(notification),
                                error: console.error
                            });
                        this.signalRService.getVariablesUsingWorker(filters.stringify(), filters);
                    });
            }
        } else {
            await this.signalRService.applyFilter(this.currentFilters, filters, [SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED])
                .then(subjects => {
                    subjects(filters, SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED)
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe({
                            next: (notification) => this.receiveRTNotification(notification),
                            error: console.error
                        });
                    this.signalRService.getVariablesUsingWorker(filters.stringify(), filters);
                });
        }
        this.currentRequestId = currentRequestId;
        this.currentFilters = filters;
    }

    receiveRTNotification(item: VariableNotification) {
        this.subscribed = true;
        // if (item.requestId.startsWith('Line---') || item.requestId === this.currentRequestId) {
        if (item.requestId === this.currentRequestId || item.requestId === VARIABLE_EMPTY_REQUEST_ID) {
            this.equipmentVariables = this.equipmentVariables.map((variable) => {
                if (variable.name === item.variableName && item.isOpcError) {
                    variable.isOpcError = item.isOpcError;
                    variable.opcErrorType = item.opcErrorType;
                }
                return variable.name === item.variableName
                    ? { ...variable, sourceTimeStamp: item.sourceTimeStamp, value: item.variableValue, valueType: item.variableValueType }
                    : variable;
            });

            const variableType = item.variableType.toUpperCase() === 'ALARM' ? 'FAILURE' : item.variableType;
            const key = `${variableType}.${item.variableName}`.toUpperCase();

            this.metadata?.variables?.texts?.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = item.variableValue));
            const textElement = this.$svg?.find(`[data-key="${'text' + key}"]`);
            if (textElement.length) {
                this.metadata?.variables?.blocks
                    .filter((b) => b['variable'] === key)
                    .forEach((block) => {
                        block.texts.filter((v) => v['variable'] === key).forEach((text) => (text['text'] = item.variableValue));
                    });
            }

            const el = this.$svg?.find(`[data-key="${key}"]`);
            if (el.length) {
                /* eslint-disable prettier/prettier */
                const state = !isActiveVariable(item.variableValue)         ? 'unknown' :
                              isRunVariable(item)                           ? 'run' :
                              item.variableType.toUpperCase() === 'WARNING' ? 'warning' :
                              variableType.toUpperCase() === 'FAILURE'      ? 'alarm' :
                              variableType.toUpperCase() === 'STATUS'       ? 'status'
                                                                            : 'unknown';
                /* eslint-enabled prettier/prettier */
                //TODO: add BOX_DETECTED
                el.removeClass().addClass(state).data('state', state);

                //if the variable didint have a previus class/color, look for it inside WarehouseConfiguration VariableColorSettings
                if ((state === 'unknown' || state === 'status') && textElement.length) {
                    let pathTextFillColor = '#eeeeee';

                    const keyWithWildCards = `${WILD_CARD_CHARACTER}.${item.variableName}`.toUpperCase();
                    const variableFound: VariableColors = this.variableColorsConfiguration.find(
                        (setting) => setting.variable.endsWith(key) || setting.variable.endsWith(keyWithWildCards),
                    );
                    if (variableFound) {
                        const defaultColor = variableFound.settings?.find((color) => +color.to === 0);
                        const foundedColor = variableFound.settings?.find((color) => +item.variableValue <= +color.to);
                        const finalColor = foundedColor ?? defaultColor;
                        if (finalColor) {
                            pathTextFillColor = finalColor.color;

                            const foreColor = this.textColorPipe.transform(pathTextFillColor);
                            textElement.attr('style', 'fill: ' + foreColor + '; stroke: ' + foreColor);
                            //textElement.attr('style', 'fill: ' + foreColor);
                        }
                    }
                    el.attr('style', 'fill:' + pathTextFillColor); // + ';stroke:' + aaa2
                }
            }
        }
    }

    async handleReconnection() {
        await this.signalRService.endSingleSubscription(this.currentFilters, [SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED]);
        this.subscribed = false; //true;
        this.loadVariableRT();
    }

    toggleViewActive() {
        this.onlyShowActive = !this.onlyShowActive;
    }

    setVariables(variables) {
        this.equipmentVariables = variables.map((v) => {
            return { ...v, equipmentType: this.currentLine?.split('-').pop() };
        });

        const existingTypes: Array<string> = _.uniq(variables.map((v) => v.type.charAt(0).toUpperCase() + v.type.slice(1)));
        this.variableTypes = ['All', ...existingTypes];
        this.sortVariables();
    }

    sortVariables() {
        const property = this.sortProperty;
        // tslint:disable-next-line:max-line-length
        const sortSetup: OrderBy<string, boolean> =
            property === ACTIVE ? { iteratees: [sortByActive, NAME], orders: [this.sortOrder, ASC] } :
            property === TYPE   ? { iteratees: [sortByType, NAME], orders: [this.sortOrder, ASC] } :
            property !== NAME   ? { iteratees: [property, NAME], orders: [this.sortOrder, ASC] }
                                : { iteratees: property, orders: this.sortOrder };
        this.equipmentVariables = _.orderBy(this.equipmentVariables, sortSetup.iteratees, sortSetup.orders);
    }

    applySort(property: SortField) {
        if (property === this.sortProperty) {
            this.sortOrder = this.sortOrder === ASC ? DESC : ASC;
        } else {
            this.sortProperty = property;
            this.sortOrder = ASC;
        }
        this.sortVariables();
    }

    showVariables() {
        this.selectedTab = TABS.variables;
    }

    showAnalytics() {
        this.selectedTab = TABS.analytics;
        this.getKpisFromEquipment();
    }

    showEquipmentView(equipmentType: string = '') {
        this.selectedTab = TABS.equipmentView;
        this.isLoadingEquipmentView = true;

        this.backgroundImage = '';
        this.metadata = null;

        this.$svg = $(this.svg.nativeElement);

        const type: string = equipmentType ? equipmentType : this.equipmentIds[1]; // 'CONVEYOR';
        const level = Level.Equipment;

        this.equipmentsService
            .getEquipmentPhotoAndMetadata(type, level)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res) => {
                    if (res && res['metadata']) {
                        this.appLoadService.getCurrentWarehouse.subscribe((res) => {
                            const baseUrl = `${res.hostName}/api`.toLowerCase();
                            const completeUrl = `${baseUrl}/designtool/ar-images/${level}/${res.warehouse}/typologies/${type}/image?${new Date().getTime()}`;
                            this.imageSecureService.setImageAsSecure(completeUrl, false).subscribe((blobImage) => {
                                this.backgroundImage = blobImage; //`${changes.typology.currentValue.photo}`;
                            });
                        });
                        this.metadata = JSON.parse(res['metadata']);

                        this.loadVariableRT();
                    }
                    this.isLoadingEquipmentView = false;
                },
                error: (err) => {
                    console.warn(`Error subscribing Photo and Metadata ${err}`);
                    this.isLoadingEquipmentView = false;
                }
            });
    }

    onHideModal(event) {
        this.onHide.emit(event);
    }

    navigateToEquipment(equipment) {
        if (equipment) {
            this.$equipmentsSearch2.prop('disabled', true);
            this.showWarningMessage = false;
            this.equipmentSearch = String(equipment);
            this.equipmentsService
                .getEquipmentLocation(this.equipmentSearch)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((res) => {
                    if (res) {
                        this.offcanvas.hideDetails();
                        this.router.navigate([
                            `warehouse/${res.warehouseId}/floor/${res.floorId}/area/${res.areaId}/zone/${res.zoneId}/line/${res.lineId}`,
                            { equipment },
                        ]);
                        this.$equipmentsSearch2.prop('disabled', false);
                    } else {
                        this.errorService.add({
                            type: 'Warning',
                            title: 'Equipment not found',
                            code: '500',
                            msg: `The equipment ${this.equipmentSearch} couldn't be found on the warehouse`,
                        });
                        this.$equipmentsSearch2.prop('disabled', false);
                    }
                });
        } else {
            this.showWarningMessage = true;
        }
    }

    onLineSelection(fqdn) {
        if (fqdn) {
            this.lineFqdn = fqdn;
            const keys = this.lineFqdn.split('-');
            this.offcanvas.hideDetails();
            this.router.navigate([`warehouse/${keys[0]}/floor/${keys[1]}/area/${keys[2]}/zone/${keys[3]}/line/${keys[4]}`]);
        }
    }

    
    
    onCreateWorkOrder(currentLine) {
        const equipmentId = currentLine.split('-').shift();
        const tooltipsEquipmentVisibles = document.querySelectorAll('.tooltip.show[id^="tooltip"]');
        if (tooltipsEquipmentVisibles) {
            tooltipsEquipmentVisibles.forEach(tooltip => {            
                this.renderer2.removeClass(tooltip, 'show');
            });
        }
        this.confirmationMessage.equipment = equipmentId;
        this.workOrderConfirmationModal.openModal();
    }

    confirmationEmited(value) {
        if (value) {
            this.isWaitingForWorkOrderResponse = true;
            this.immsService.createWorkOrder(this.confirmationMessage.equipment).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res) => { 
                    this.isWaitingForWorkOrderResponse = false;
                    this.handleImmsWorkOrderResponse(res.workOrderId);
                },
                error: (err) => {
                    this.isWaitingForWorkOrderResponse = false;
            }
        });
        this.workOrderConfirmationModal.closeModal();
    }
}

    handleImmsWorkOrderResponse(msg, time = 7000) {
        const tempUuid = uuid();
        const newImmsWorkOrderToast = {
            uuid: tempUuid,
            code: 200,
            title: 'Success',
            msg: `The work order was create with ID <strong>${msg}</strong>`,
        }
        this.immsWorkOrderToasts.push(newImmsWorkOrderToast);
 
        setTimeout(() => {
            $('.toast').toast();
            $('.toast').toast('show');
            setTimeout(() => {
                this.immsWorkOrderToasts = this.immsWorkOrderToasts.filter(f => f.uuid !== tempUuid);
                $('.toast').toast();
                $('.toast').toast('show');
            }, time);
        }, 0);
    }
}

interface OrderBy<T, R> {
    iteratees?: Many<ObjectIteratee<T> | ObjectIterator<T, R>>;
    orders?: Many<boolean | SortOrder>;
}

const sortByActive = (v: EquipmentVariable): boolean => !isActiveVariable(v.value);
const sortByType = (v: EquipmentVariable): number =>
    v.name.toLowerCase() === 'run'
        ? -1
        : [VariableType.ALARM, VariableType.WARNING, VariableType.STATUS, VariableType.COMMAND].indexOf(pascalCaseType(v) as VariableType);
const pascalCaseType = (v: EquipmentVariable): string => v.type.charAt(0).toUpperCase() + v.type.slice(1);
