import { LineStatusNotification, RawLineStatusNotification } from '../events/line-status';

// Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
// REST API - Initial load
// const initialLineStatusMapper = (lineStatusNotification: RawInitialLineStatusNotification): LineStatusNotification => {
//     return {
//         floorId: lineStatusNotification['floorid'] || lineStatusNotification['floorId'],
//         areaId: lineStatusNotification['areaid'] || lineStatusNotification['areaId'],
//         zoneId: lineStatusNotification['zoneid'] || lineStatusNotification['zoneId'],
//         lineId: lineStatusNotification['lineid'] || lineStatusNotification['lineId'],
//         state: lineStatusNotification.state,
//         description: lineStatusNotification.variableName,
//         //device: lineStatusNotification.device,
//         timestamp: lineStatusNotification.timestamp,
//         serverts: lineStatusNotification.serverts,
//         sourcets: lineStatusNotification.sourcets,
//         source: lineStatusNotification.source,
//         priority: lineStatusNotification.priority,
//     };
// };

// // SignalR - Socket notifications
// const lineStatusMapper = (lineStatusNotification: RawLineStatusNotification): LineStatusNotification => {
//     return {
//         floorId: lineStatusNotification.floorid || lineStatusNotification.floorid,
//         areaId: lineStatusNotification.areaid,
//         zoneId: lineStatusNotification.zoneid,
//         lineId: lineStatusNotification.lineid,
//         state: lineStatusNotification.state,
//         description: lineStatusNotification.variableName,
//         //device: lineStatusNotification.device,
//         timestamp: lineStatusNotification.timestamp,
//         serverts: lineStatusNotification.serverts,
//         sourcets: lineStatusNotification.sourcets,
//         source: lineStatusNotification.source,
//         priority: lineStatusNotification.priority,
//     };
// };

// export { lineStatusMapper, initialLineStatusMapper };

const lineStatusMapper = (lineStatusNotification: RawLineStatusNotification): LineStatusNotification => {
    return {
        floorId: lineStatusNotification.floorid || lineStatusNotification.floorId,
        areaId: lineStatusNotification.areaid || lineStatusNotification.areaId,
        zoneId: lineStatusNotification.zoneid || lineStatusNotification.zoneId,
        lineId: lineStatusNotification.lineid || lineStatusNotification.lineId,
        state: lineStatusNotification.state,
        description: lineStatusNotification.variableName,
        //device: lineStatusNotification.device,
        timestamp: lineStatusNotification.timestamp,
        serverts: lineStatusNotification.serverts,
        sourcets: lineStatusNotification.sourcets,
        source: lineStatusNotification.source,
        priority: lineStatusNotification.priority,
    };
};

export { lineStatusMapper };