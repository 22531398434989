import { EquipmentStatusNotification, RawEquipmentStatusNotification, RawInitialEquipmentStatusNotification } from '../events/equipment-status';

const initialEquipmentStatusMapper = (equipmentStatusNotification: RawInitialEquipmentStatusNotification): EquipmentStatusNotification => {
    return {
        floorId: equipmentStatusNotification.floorid,
        areaId: equipmentStatusNotification.areaid,
        zoneId: equipmentStatusNotification.zoneid,
        lineId: equipmentStatusNotification.lineid,
        equipmentId: equipmentStatusNotification.equipmentId,
        //equipmentType: equipmentStatusNotification.equipmentId,
        equipmentType: equipmentStatusNotification.equipmentType,
        //equipmentId: equipmentStatusNotification.equipmentType,
        state: equipmentStatusNotification.state,
        variableName: equipmentStatusNotification.variableName.replace(/^(FAILURE\.)/, ''),
        priority: equipmentStatusNotification.priority,
        timestamp: equipmentStatusNotification.timestamp,
        serverts: equipmentStatusNotification.serverts,
    };
};

const equipmentStatusMapper = (equipmentStatusNotification: RawEquipmentStatusNotification): EquipmentStatusNotification => {
    return {
        floorId: equipmentStatusNotification.floorid,
        areaId: equipmentStatusNotification.areaid,
        zoneId: equipmentStatusNotification.zoneid,
        lineId: equipmentStatusNotification.lineid,
        equipmentId: equipmentStatusNotification.equipmentId,
        //equipmentType: equipmentStatusNotification.equipmentId,
        equipmentType: equipmentStatusNotification.equipmentType,
        //equipmentId: equipmentStatusNotification.equipmentType,
        state: equipmentStatusNotification.state,
        variableName: equipmentStatusNotification.variableName.replace(/^(FAILURE\.)/, ''),
        priority: equipmentStatusNotification.priority,
        timestamp: equipmentStatusNotification.timestamp,
        serverts: equipmentStatusNotification.serverts,
    };
};

export { equipmentStatusMapper, initialEquipmentStatusMapper };
