import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Alert, AlertSnapshotVariable } from './alert';
import { Alerts, AlertsCount } from './alerts';
// import { mapNotification } from '@app/notifications/shared/mappers/notification.mapper';
import { AlertsActiveStatus } from '@app/notifications/shared/events/alert-status';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Injectable({ providedIn: 'root' })
export class AlertsService {
    baseUrl: string;
    warehouse: string;

    private readonly http = inject(HttpClient);
    private readonly appLoadService = inject(AppLoadService);

    constructor() { }

    getAlertsActiveCount(): Observable<AlertsCount> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `alerts/${this.warehouse}/active/count`;
                return this.http.get<AlertsCount>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            //shareReplay(),
        );
    }

    // tslint:disable-next-line:max-line-length
    // getAlertsActive(): Observable<EquipmentAlarmNotification[]> {
    //     return this.appLoadService.getCurrentWarehouse.pipe(
    //         switchMap((res) => {
    //             this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
    //             this.warehouse = res.warehouse;
    //             // tslint:disable-next-line:max-line-length
    //             const endpoint = environment.mode === 'front' ? `alertsActive` : `alerts/${this.warehouse}/active`;
    //             return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
    //         }),
    //         take(1),
    //         map((notifications: AlertsActiveStatus[]) => {
    //             const mappedAlerts: EquipmentAlarmNotification[] = notifications.map((notification) => {
    //                 return {
    //                     floorId: notification.floorid,
    //                     areaId: notification.areaid,
    //                     zoneId: notification.zoneid,
    //                     lineId: notification.lineid,
    //                     equipmentId: notification.equipmentId,
    //                     equipmentType: notification.equipmentType,
    //                     state: notification.state,
    //                     variableName: notification.variableName,
    //                     priority: notification.priority,
    //                     source: notification.source,
    //                     sourceTimeStamp: notification.sourceTimeStamp.toString(),
    //                     serverts: notification.serverTimeStamp.toString()
    //                 }
    //             });
    //             return mappedAlerts;
    //         }),
    //         //shareReplay(),
    //     );
    // }

    getAlertsByCustom(customId): Observable<any[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `alerts/${this.warehouse}/custom-map/${customId}`;
                return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            // map((notifications) => {
            //     const mappedAlerts: Alert[] = notifications.map((notification) =>
            //         mapNotification(ALERT_STATE_CHANGED, notification),
            //     );
            //     return mappedAlerts;
            // }),
            //shareReplay(),
        );
    }

    getActiveAlerts(floorId?: string, areaId?: string, zoneId?: string, lineId?: string): Observable<any[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = !!lineId  ? `alerts/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}` :
                                 !!zoneId  ? `alerts/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}` :
                                 !!areaId  ? `alerts/${this.warehouse}/floors/${floorId}/areas/${areaId}` :
                                 !!floorId ? `alerts/${this.warehouse}/floors/${floorId}`
                                           : `alerts/${this.warehouse}/active`;
                return this.http.get<AlertsActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            // map((notifications) => {
            //     const mappedAlerts: Alert[] = notifications.map((notification) =>
            //         mapNotification(ALERT_STATE_CHANGED, notification),
            //     );
            //     return mappedAlerts;
            // }),
        );
    }

    getAlertsHistoric({
        floorId = null,
        areaId = null,
        zoneId = null,
        lineId = null,
        page = 0,
        pageSize = 20,
        from = dayjs().subtract(1, 'days'),
        to = dayjs(),
        priority = null,
        description = null,
        equipmentId = null,
        equipmentType = null,
    }): Observable<Alerts> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const utcFrom = dayjs(from.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                const utcTo = dayjs(to.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                /* eslint-disable */
                let historicQuery = !!lineId  ? `history/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}` :
                                    !!zoneId  ? `history/floors/${floorId}/areas/${areaId}/zones/${zoneId}` :
                                    !!areaId  ? `history/floors/${floorId}/areas/${areaId}` :
                                    !!floorId ? `history/floors/${floorId}`
                                              : `history`;
                /* eslint-enable */
                historicQuery += `?pagesize=${pageSize}&pagenumber=${page}&from=${utcFrom}&to=${utcTo}`;
                if (priority) {
                    historicQuery += `&priority=${priority}`;
                }
                if (description) {
                    historicQuery += `&description=${description}`;
                }
                if (equipmentId) {
                    historicQuery += `&equipmentId=${equipmentId}`;
                }
                if (equipmentType) {
                    historicQuery += `&equipmentTypeFilter=${equipmentType}`;
                }
                return this.http.get<Alerts>(`${this.baseUrl}/alerts/${this.warehouse}/${historicQuery}`);
            }),
            take(1),
            //shareReplay(),
        );
    }

    getAlertDetails(alarm: Alert): Observable<Array<AlertSnapshotVariable>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                const fqdn = `${this.warehouse}.${alarm.floorId}.${alarm.areaId}.${alarm.zoneId}.${alarm.lineId}.${alarm.equipmentId}.${alarm.equipmentType}.FAILURE.${alarm.description}`;
                const cleanFqdn = String(fqdn).replaceAll('<span class="highlight">', '').replaceAll('</span>', '');
                const alertDate = dayjs(alarm.alarmSourceTimeStamp.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `variables/${this.warehouse}/equipment/snapshot?fqdn=${cleanFqdn}&date=${alertDate}`;
                return this.http.get<Array<AlertSnapshotVariable>>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            //shareReplay()
        );
    }

    getAlarmsOrigin(idFloor: string, idArea: string, idZone: string, variableName: string = '', depth: string = '1'): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const params = ['All', ''].includes(variableName) ? { depth: 1 } : { depth: depth, variablename: variableName }
                const v2Version = ['All', ''].includes(variableName) ? '' : '/v2'
                const endpoint = `warehouse/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}/alarm-origin${v2Version}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`, { params: params }); //{ params: { depth: 1 } }
            }),
        );
    }

    hasAlarmsOrigin(): Observable<boolean> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `warehouse/${this.warehouse}/alarm-origin`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
        );
    }

    alarmsOriginMonitoredErrors(): Observable<string[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `warehouse/${this.warehouse}/alarm-origin/monitoredErrors`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
        );
    }
}
