"use strict";

var isCallable = require("./object/is-callable");
module.exports = function (value) {
  try {
    if (value && isCallable(value.toString)) return value.toString();
    return String(value);
  } catch (e) {
    return "<Non-coercible to string value>";
  }
};