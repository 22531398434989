import { Component, inject } from '@angular/core';
import { ColorsService } from '@app/shared/services/colors.service';

@Component({
    selector: 'app-svg-patterns',
    templateUrl: './svg-patterns.component.html',
    styleUrls: ['./svg-patterns.component.scss'],
    standalone: true,
})
export class SvgPatternsComponent {
    private readonly colorsService = inject(ColorsService);

    colorBoxes: string = this.colorsService.getColor('status-boxes'); //'#8B572A';
    colorRunning: string = this.colorsService.getColor('status-running'); //'#2da45d';.
    colorLWDO: string = this.colorsService.getColor('status-lwdo'); //'#a938cc';
    colorWarning: string = this.colorsService.getColor('status-warning'); //'#a938cc';
    colorErrors: string = this.colorsService.getColor('status-errors'); //'#FE840E';
    colorCritical: string = this.colorsService.getColor('status-critical'); //'#ff2800';
    colorNoData: string = this.colorsService.getColor('status-no-data'); //'#eee';

    constructor() { }
}
