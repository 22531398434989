import { Component, inject, OnDestroy } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgIf } from '@angular/common';

@Component({
    selector: 'core-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class PreloaderComponent implements OnDestroy {
    loading = true;
    msg = '';
    private ngUnsubscribe: Subject<any> = new Subject();

    private readonly router = inject(Router);

    constructor() {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });
    }
    ngOnDestroy(): void {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.loading = true;
            $('html').addClass('no-scroll');
            switch (true) {
                case event.url === '/':
                    this.msg = `home page...`;
                    break;
                case event.url === '/alerts':
                    this.msg = `alerts page...`;
                    break;
                case event.url === '/variables':
                    this.msg = `variables page...`;
                    break;
                case event.url === '/connections':
                    this.msg = `communications page...`;
                    break;
                case event.url === '/reports/daily':
                    this.msg = `daily report...`;
                    break;
                case /reports\/equipments\//.test(event.url):
                    this.msg = `equipments report...`;
                    break;
                case /reports\/equipments-custom\//.test(event.url):
                    this.msg = `equipments report...`;
                    break;
                case /reports\/errors\//.test(event.url):
                    this.msg = `errors report...`;
                    break;
                case /reports\/kpis\//.test(event.url):
                    this.msg = `kpi's report...`;
                    break;
                case /floor\/[a-zA-Z0-9-_]{2,}\/area\/[a-zA-Z0-9-_]{2,}\/zone\/[a-zA-Z0-9-_]{2,}\/line\/[a-zA-Z0-9-_]{2,}/.test(event.url):
                    this.msg = `line page...`;
                    break;
                case /floor\/[a-zA-Z0-9-_]{2,}\/area\/[a-zA-Z0-9-_]{2,}\/zone\/[a-zA-Z0-9-_]{2,}/.test(event.url):
                    this.msg = `zone page...`;
                    break;
                case /floor\/[a-zA-Z0-9-_]{2,}\/area\/[a-zA-Z0-9-_]{2,}/.test(event.url):
                    this.msg = `area page...`;
                    break;
                case /floor\/[a-zA-Z0-9-_]{2,}/.test(event.url):
                    this.msg = `floor page...`;
                    break;
                default:
                    this.msg = ``;
            }
        }
        if (event instanceof NavigationEnd) {
            $('html').removeClass('no-scroll');
            this.loading = false;
        }
        if (event instanceof NavigationCancel) {
            $('html').removeClass('no-scroll');
            this.loading = false;
        }
        if (event instanceof NavigationError) {
            $('html').removeClass('no-scroll');
            this.loading = false;
        }
    }
}
