import { inject, OnDestroy, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';
import { VariableFilter } from '@app/notifications/shared/events/variable-status';
import { UserConfiguration } from '../models/configurations';
import { IdentifiersPreferences } from '@app/map/home/shared/structure';

// const EQUALS            = 'Equals';
// const NOT_EQUALS        = 'NotEquals';
// const GREATER           = 'Greater';
// const GREATER_OR_EQUALS = 'GreaterOrEquals';
// const LESSER            = 'Lesser';
// const LESSER_OR_EQUALS  = 'LesserOrEquals';
const BETWEEN = 'Between';

@Pipe({
    name: 'displayFilter',
    standalone: true,
})
export class DisplayFilterPipe implements PipeTransform, OnDestroy {
    identifiersPreferences: IdentifiersPreferences = { Floor: true, Area: true, Zone: true, Line: true };
    userConfiguration: UserConfiguration;
    private ngUnsubscribe: Subject<any> = new Subject();

    private readonly sanitizer = inject(DomSanitizer);
    private readonly appLoadService = inject(AppLoadService);

    constructor() {
        this.appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            this.userConfiguration = res;
            this.identifiersPreferences = { ...this.userConfiguration.identifiersPreferences };
        });
    }

    transform(filter: VariableFilter): any {
        let { floorId, areaId, zoneId, lineId, equipmentId, equipmentType, variableType, variableName, fqnPattern, condition } = filter;
        if (!this.identifiersPreferences.Floor) {
            floorId = floorId?.replace('F', '');
        }
        if (!this.identifiersPreferences.Area) {
            areaId = areaId?.replace('A', '');
        }
        if (!this.identifiersPreferences.Zone) {
            zoneId = zoneId?.replace('Z', '');
        }
        if (!this.identifiersPreferences.Line) {
            lineId = lineId?.replace('EQL', '');
        }
        let response = this.textOrDefault(floorId, `Floor is ${floorId?.toUpperCase()}. `);
        response += this.textOrDefault(areaId, `Area is ${areaId?.toUpperCase()}. `);
        response += this.textOrDefault(zoneId, `Zone is ${zoneId?.toUpperCase()}. `);
        response += this.textOrDefault(lineId, `Line is ${lineId?.toUpperCase()}. `);
        response += this.textOrDefault(equipmentId, `Equipment is ${equipmentId?.toUpperCase()}. `);
        response += this.textOrDefault(equipmentType, `Equipment type is ${equipmentType?.toUpperCase()}. `);
        response += this.textOrDefault(variableType, `Variable type is ${variableType?.toUpperCase()}. `);
        response += this.textOrDefault(variableName, `Variable is ${variableName?.toUpperCase()}. `);
        response += this.textOrDefault(fqnPattern, `FQDN is ${fqnPattern?.toUpperCase()}. `);
        if (condition) {
            response += Object.keys(condition).reduce((acc, key) => {
                const value =
                    ['Alarm', 'Warning'].includes(variableType) && 'true' === condition[key].Value
                        ? 'Active'
                        : ['Alarm', 'Warning'].includes(variableType) && 'false' === condition[key].Value
                            ? 'Inactive'
                            : condition[key].Value;
                return key === BETWEEN ? `Value Between ${condition[key].MinValue} and ${condition[key].MaxValue}. ` : `Value ${key} ${value}. `;
            }, '');
        }
        return this.sanitizer.sanitize(SecurityContext.HTML, response);
    }

    textOrDefault(value: string, text: string) {
        return value ? text : '';
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }
}
