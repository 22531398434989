import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ImageSecureService {
    constructor() { }

    http = inject(HttpClient);
    sanitizer = inject(DomSanitizer);

    setImageAsSecure(url, asSafeUrl: boolean = true): Observable<SafeUrl | string> {
        //transform(url, asSafeUrl: boolean = true): Observable<SafeUrl | string> {
        if (!url) return of(null);
        return this.http.get(url, { responseType: 'blob' }).pipe(
            take(1),
            //i decide to control the return valur with asSafeUrl, becaouse the svg:image needs a string, ToDo: use the svg:image with a SafeUrl return
            map((val) => {
                return asSafeUrl ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)) : URL.createObjectURL(val);
            }),
        );
    }
}
