/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { openDB } from '@tempfix/idb';

export enum IDB_STORES {
    HTTP = 'HTTP_STORE',
    NOTIFICATIONS = 'NOTIFICARION_STORE',
}

@Injectable({ providedIn: 'root' })
export class IdbService {
    private dbPromise: Promise<any>;

    constructor() {
        this.dbPromise = openDB('AUWA_DB', 1.0, {
            upgrade(db) {
                db.createObjectStore(IDB_STORES.HTTP, {
                    keyPath: 'url',
                });
                // db.createObjectStore(IDB_STORES.NOTIFICATIONS);
            },
            blocked() {
                console.log('blocked');
            },
            blocking() {
                console.log('blocking');
            },
            terminated() {
                console.log('terminated');
            },
        });
    }

    get$(storeName: string, key: string): Observable<any> {
        return from(this.dbPromise.then((db) => db.get(storeName, key)));
    }

    put$(storeName: string, value: any): Observable<any> {
        return from(this.dbPromise.then((db) => db.put(storeName, value)));
    }

    async clear(storeName: string): Promise<any> {
        return (await this.dbPromise).clear(storeName);
    }
}
