// export const WarehouseVariablesStatusSupportedEvents = {
//     WAREHOUSE_STATUS_VARIABLE_CHANGED: 'WarehouseStatusVariableChanged',
// };

/* eslint-disable @typescript-eslint/naming-convention */
export enum VariableValueType {
    String = 'String',
    Numeric = 'Numeric',
    Boolean = 'Boolean',
}

export const WILD_CARD_CHARACTER = '-';
export const VARIABLE_EMPTY_REQUEST_ID = 'WarehouseVariableStatus_InitialLoad';
export const ACTIVE_VARIABLES = 'ActiveVariables';

export interface VariableNotification {
    requestId: string;
    floorId: string;
    areaId: string;
    lineId: string;
    zoneId: string;
    equipmentId: string;
    equipmentType: string;
    fqn: string;
    source: string;
    timestamp: string;
    sourceTimeStamp: string;
    serverTimeStamp: string;
    variableName: string;
    variableType: string;
    variableValue: any;
    variableValueType: string;
    isOpcError?: boolean;
    opcErrorType?: string;
}

export interface VariableActiveStatusGroup {
    notifications: Array<VariableActiveStatus>;
}

export interface VariableActiveStatus {
    requestId?: string;
    floorid: string;
    areaid: string;
    lineid: string;
    zoneid: string;
    equipmentId: string;
    equipmentType: string;
    fqn: string;
    source: string;
    timestamp: string;
    sourceTimeStamp: string;
    serverTimeStamp: string;
    variableName: string;
    variableType: string;
    value: any;
    valueType: string;
    isOpcError?: boolean;
    opcErrorType?: string;
}

export class VariableFilter {
    requestId?: string;
    warehouseId?: string;
    floorId?: string;
    areaId?: string;
    zoneId?: string;
    lineId?: string;
    fqn?: string;
    faz?: string;
    from?: any;
    to?: any;
    equipmentId?: string;
    equipmentType?: string;
    variableType?: string;
    variableName?: string;
    fqnPattern?: string;
    scanners?: string;
    maxNotificationItems?: number;
    condition?: any;
    orderColumn?: string;
    orderType?: string;
}

export class VariableFilterWithComparator extends VariableFilter {
    fromComparator?: any;
    toComparator?: any;
}

export class VariableFilters {
    requestId: string;
    orderColumn: string;
    orderType: string;
    maxNotificationItems: number;
    filters: VariableFilter[];

    constructor(object?: { requestId: string, filters: VariableFilter[], maxNotificationItems: number, orderColumn: string, orderType: string });
    constructor(object: { requestId: string, filters: VariableFilter[], maxNotificationItems: number, orderColumn: string, orderType: string }) {
        this.requestId = object?.requestId;
        this.orderColumn = object?.orderColumn;
        this.orderType = object?.orderType;
        this.maxNotificationItems = object?.maxNotificationItems ?? 500;
        this.filters = object?.filters ?? [];
    }

    stringify(): string {
        return JSON.stringify(this);
    }
}

export interface Metadata {
    variables: {
        shapes: Array<string>;
        texts: Array<string>;
        blocks?: Array<{
            variable?: string;
            description?: string;
            transform?: string;
            shapes: Array<any>;
            texts: Array<any>;
        }>;
    };
}

export enum Level {
    Equipment = 'EQUIPMENT',
    Line = 'LINE',
}
