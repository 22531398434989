/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MapState, MapStates } from '@app/shared/models/map-state';

const availableStates: Array<MapState> = [
    { position: 0, id: MapStates.run, label: 'Run', visible: true },
    { position: 1, id: MapStates.box, label: 'Box', visible: true },
    { position: 2, id: MapStates.without_permission, label: 'Without Permission', visible: true },
    { position: 3, id: MapStates.alert_low, label: 'Alert Low', visible: true },
    { position: 4, id: MapStates.alert, label: 'Alert', visible: true },
    { position: 5, id: MapStates.critical, label: 'Critical', visible: true },
    { position: 6, id: MapStates.warning, label: 'Warning', visible: true },
    { position: 7, id: MapStates.disconnected, label: 'Disconnected', visible: true },
];

@Injectable({ providedIn: 'root' })
export class StateService {
    private versionMap = new BehaviorSubject(null);
    getVersionMap = this.versionMap.asObservable();
    //notificationsMap = new Map<string, NotificationDetails>();
    private filterStates = new BehaviorSubject(availableStates);
    getFilterStates = this.filterStates.asObservable();
    private fullscreenState = new BehaviorSubject(false);
    isFullscreen = this.fullscreenState.asObservable();
    private showFiltersState = new BehaviorSubject(false);
    showFilters = this.showFiltersState.asObservable();
    private apiUri = new BehaviorSubject('');
    getApiUri = this.apiUri.asObservable();
    // private reconnectWS = new Subject();
    // getReconnectWS = this.reconnectWS.asObservable();

    constructor() { }

    setApiUri(uri: string) {
        this.apiUri.next(uri);
    }

    setVersionMap(version: string) {
        this.versionMap.next(version);
    }

    setFilterStates(states: Array<MapState>) {
        this.filterStates.next(states);
    }

    setFullscreen(isFullscreen: boolean) {
        this.fullscreenState.next(isFullscreen);
    }

    setShowFilters(showFilters: boolean) {
        this.showFiltersState.next(showFilters);
    }

    // public forceReconnectWS() {
    //     this.reconnectWS.next(true);
    // }
}
