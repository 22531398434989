"use strict";

var callable = require("es5-ext/object/valid-callable");
module.exports = function (userNormalizer) {
  var normalizer;
  if (typeof userNormalizer === "function") return {
    set: userNormalizer,
    get: userNormalizer
  };
  normalizer = {
    get: callable(userNormalizer.get)
  };
  if (userNormalizer.set !== undefined) {
    normalizer.set = callable(userNormalizer.set);
    if (userNormalizer.delete) normalizer.delete = callable(userNormalizer.delete);
    if (userNormalizer.clear) normalizer.clear = callable(userNormalizer.clear);
    return normalizer;
  }
  normalizer.set = normalizer.get;
  return normalizer;
};