import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { LinesService } from '@lines/shared/lines.service';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { AppLoadService } from '@app/app-load.service';
import * as _ from 'lodash-es';

export const LinesResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot): Observable<any> => {
    const linesService = inject(LinesService);
    const equipmentsService = inject(EquipmentsService);
    const appLoadService = inject(AppLoadService);

    return forkJoin([
        linesService.getLine(route.params.floorId, route.params.areaId, route.params.zoneId, route.params.lineId).pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el provider al recuperar el mapa de la linea de equipos -> ' + err);
                return of(null);
            }),
        ),
        equipmentsService.getEquipmentTypes().pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar los tipos de equipos');
                return of(null);
            }),
        ),
        appLoadService.getCurrentWarehouse.pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar el almacen actual', err);
                return of(null);
            }),
        ),
        linesService.getIcons().pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el provider al recuperar los iconos -> ' + err);
                return of(null);
            }),
        ),
        appLoadService.getCurrentConfiguration.pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar la configuracion', err);
                return of(null);
            }),
        ),
    ]).pipe(
        map((x) => {
            return {
                map: x[0],
                equiopmentTypes: x[1],
                warehouse: x[2],
                icons: x[3],
                configuration: x[4],
            };
        }),
    );
}