// Limit cache size, LRU (least recently used) algorithm.

"use strict";

var toPosInteger = require("es5-ext/number/to-pos-integer"),
  lruQueue = require("lru-queue"),
  extensions = require("../lib/registered-extensions");
extensions.max = function (max, conf, options) {
  var postfix, queue, hit;
  max = toPosInteger(max);
  if (!max) return;
  queue = lruQueue(max);
  postfix = options.async && extensions.async || options.promise && extensions.promise ? "async" : "";
  conf.on("set" + postfix, hit = function (id) {
    id = queue.hit(id);
    if (id === undefined) return;
    conf.delete(id);
  });
  conf.on("get" + postfix, hit);
  conf.on("delete" + postfix, queue.delete);
  conf.on("clear" + postfix, queue.clear);
};