"use strict";

var callable = require("./valid-callable"),
  forEach = require("./for-each"),
  call = Function.prototype.call;
module.exports = function (obj, cb /*, thisArg*/) {
  var result = {},
    thisArg = arguments[2];
  callable(cb);
  forEach(obj, function (value, key, targetObj, index) {
    result[key] = call.call(cb, thisArg, value, key, targetObj, index);
  });
  return result;
};