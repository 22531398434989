"use strict";

module.exports = function (length) {
  if (!length) {
    return function () {
      return "";
    };
  }
  return function (args) {
    var id = String(args[0]),
      i = 0,
      currentLength = length;
    while (--currentLength) {
      id += "\u0001" + args[++i];
    }
    return id;
  };
};