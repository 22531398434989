import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textColor',
    standalone: true,
})
export class TextColorPipe implements PipeTransform {
    transform(value: string): string {
        const hex = value.replace('#', '');
        const colorRed = parseInt(hex.substring(0, 0 + 2), 16);
        const colorGreen = parseInt(hex.substring(2, 2 + 2), 16);
        const colorBlue = parseInt(hex.substring(4, 4 + 2), 16);
        const brightness = (colorRed * 299 + colorGreen * 587 + colorBlue * 114) / 1000;
        return brightness > 175 ? '#000000' : '#FFFFFF';
    }
}
