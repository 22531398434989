<ul class="navbar-nav ms-auto" *ngIf="warehouse">
    <li class="nav-item item-1">
        <a class="nav-link" (click)="goToHome()" accesskey="1" auwaOpenNewTab
            >Home
            <span class="sr-only">(current)</span>
        </a>
    </li>
    <li class="nav-item item-2 dropdown">
        <a id="alertsDropdown" tabindex="0" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Alerts</a>
        <div class="dropdown-menu alerts-dropdown" aria-labelledby="alertsDropdown">
            <i class="fas fa-caret-up" aria-hidden="true"></i>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'alerts', 'active']" accesskey="2" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Active</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'alerts', 'history']" accesskey="2" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>History</div>
            </a>
            <!-- <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'alerts', 'alarm-origin']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Alarm origins</div>
            </a> -->
        </div>
    </li>
    <li class="nav-item item-3 dropdown">
        <a id="warningsDropdown" tabindex="0" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Warnings</a>
        <div class="dropdown-menu warnings-dropdown" aria-labelledby="warningsDropdown">
            <i class="fas fa-caret-up" aria-hidden="true"></i>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'warnings', 'active']" accesskey="3" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Active</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'warnings', 'history']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>History</div>
            </a>
        </div>
    </li>
    <li class="nav-item item-4 dropdown">
        <a id="variablesDropdown" tabindex="0" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            >Variables</a
        >
        <div class="dropdown-menu variables-dropdown" aria-labelledby="variablesDropdown">
            <i class="fas fa-caret-up" aria-hidden="true"></i>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'variables', 'active']" accesskey="4" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Active</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'variables', 'history']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>History</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'variables', 'dashboard']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Dashboard</div>
            </a>
        </div>
    </li>
    <li class="nav-item item-5 dropdown">
        <a id="scannersDropdown" tabindex="0" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Scanners</a>
        <div class="dropdown-menu scanners-dropdown" aria-labelledby="scannersDropdown">
            <i class="fas fa-caret-up" aria-hidden="true"></i>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'scanners', 'active']" accesskey="5" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Active</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'scanners', 'history']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>History</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'scanners', 'trends']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Trends</div>
            </a>
        </div>
    </li>
    <li class="nav-item item-6 dropdown">
        <a id="reportsDropdown" tabindex="0" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Reports</a>
        <div class="dropdown-menu reports-dropdown" aria-labelledby="reportsDropdown">
            <i class="fas fa-caret-up" aria-hidden="true"></i>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'reports', 'daily']" accesskey="6" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Daily report</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'reports', 'equipments']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Equipments</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'reports', 'stats']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Stats</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'reports', 'alerts']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Alerts</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'reports', 'kpis']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>KPI´S</div>
            </a>
        </div>
    </li>
    <li class="nav-item item-7 dropdown">
        <a id="analyticsDropdown" tabindex="0" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            >Analytics</a
        >
        <div class="dropdown-menu analytics-dropdown" aria-labelledby="analyticsDropdown">
            <i class="fa fa-caret-up" aria-hidden="true"></i>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'analytics', 'table']" accesskey="7" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>KPI table</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'analytics', 'top']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Top 50</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'analytics', 'comparator']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Comparator</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'analytics', 'errors']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Errors</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'analytics', 'errortrend']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Error trend</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'analytics', 'heat']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Heat map</div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'analytics', 'last24hours']" auwaOpenNewTab>
                <div class="dropdown-item-wrapper"><i class="fa fa-circle-o" aria-hidden="true"></i>Last 24 hours</div>
            </a>
        </div>
    </li>
    <li class="nav-item item-8">
        <a class="nav-link" [routerLink]="['/warehouse', warehouse.warehouse, 'dashboard']" accesskey="8" auwaOpenNewTab>Dashboard</a>
    </li>
</ul>
<div class="navbar-items-inline d-none d-lg-block">
    <li class="nav-item dropdown icons-dropdown" data-bs-toggle="offcanvas" data-bs-target="#offcanvasArchives" aria-controls="offcanvasArchives">
        <div id="checkArchivesDropdown" class="dropdown-link">
            <i class="fa fa-archive"></i>
            <div class="badge">{{ numOfArchives }}</div>
        </div>
    </li>
    <li class="nav-item dropdown icons-dropdown" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEquipments" aria-controls="offcanvasEquipments">
        <div id="velocimeterDropdown" class="dropdown-link">
            <i class="icon-velocimeter"></i>
        </div>
    </li>
    <li class="nav-item dropdown icons-dropdown" [routerLink]="['/warehouse', warehouse.warehouse, 'alerts', 'active']">
        <div id="numOfAlertsDropdown" class="dropdown-link">
            <i class="icon-alert"></i>
            <div class="badge">{{ numOfAlerts }}</div>
        </div>
    </li>
    <li class="nav-item dropdown">
        <i id="configsDropdown" class="icon-menu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
        <div class="dropdown-menu nav-dropdown" aria-labelledby="configsDropdown">
            <i class="fas fa-caret-up" aria-hidden="true"></i>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'settings']">
                <div class="dropdown-item-wrapper"><i class="fas fa-cog"></i><span>Settings</span></div>
            </a>
            <a class="dropdown-item dropdown-toggle" *ngIf="warehouses.length > 1">
                <div class="dropdown-item-wrapper"><i class="icon-dashboard"></i><span>Change Warehouse</span></div>
                <div class="dropdown-menu">
                    <a class="dropdown-item" [routerLink]="['landing']">
                        <div class="dropdown-item-wrapper"><i class="icon-view_all" aria-hidden="true"></i><span>All warehouses</span></div>
                    </a>
                    <ng-template ngFor let-wh [ngForOf]="warehouses">
                        <a class="dropdown-item" *ngIf="wh.warehouse !== warehouse.warehouse" (click)="changeWarehouse(wh)">
                            <div class="dropdown-item-wrapper">
                                <i class="fa fa-share" aria-hidden="true"></i><span>{{ wh.displayName }}</span>
                            </div>
                        </a>
                    </ng-template>
                </div>
            </a>
            <a class="dropdown-item" (click)="toggleFullscreen()" accesskey="f">
                <div class="dropdown-item-wrapper">
                    <i class="icon-fullscreen"></i><span>{{ isFullscreen ? 'Exit' : '' }} Fullscreen Mode</span>
                </div>
            </a>
            <a class="dropdown-item" (click)="refreshMapAndClearLocalStorage()" accesskey="r">
                <div class="dropdown-item-wrapper"><i class="fa fa-sync"></i><span>Refresh Map</span></div>
            </a>
            <a class="dropdown-item" (click)="toggleExtraData()" accesskey="r">
                <div class="dropdown-item-wrapper">
                    <i class="fa" [ngClass]="!viewExtraData ? 'fa-eye' : 'fa-eye-slash'"></i><span>{{ !viewExtraData ? 'Show' : 'Hide' }} extended map</span>
                </div>
            </a>
            <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#helpModal">
                <div class="dropdown-item-wrapper"><i class="icon-help"></i><span>Help</span></div>
            </a>
            <a class="dropdown-item" [routerLink]="['/warehouse', warehouse.warehouse, 'userManual']">
                <div class="dropdown-item-wrapper"><i class="icon-help"></i><span>User Manual</span></div>
            </a>
            <a class="dropdown-item" (click)="logoutButtonClick()">
                <div class="dropdown-item-wrapper"><i class="icon-close_session"></i><span>Sign Out</span></div>
            </a>
            <div style="color: #95a6a7" class="float-end px-4">
                <small>v.{{ version }}</small>
            </div>
            <div style="color: #95a6a7; line-height: 1" class="float-end px-4" *ngIf="mapVersion">
                <small>Map: {{ mapVersion }}</small>
            </div>
        </div>
    </li>
    <!-- <p>{{userName || 'User'}}<br><span>{{ warehouse.displayName }}</span></p>
    <div><img src="assets/img/default-avatar.jpg"></div> -->
</div>
<div class="d-block d-lg-none">
    <div class="dropdown-divider"></div>
    <ul class="navbar-nav ms-auto">
        <li class="nav-item item-5 dropdown">
            <a class="nav-link" [routerLink]="['/warehouse', warehouse.warehouse, 'settings']">Settings</a>
            <a id="warehousesDropdown" tabindex="0" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                >Change warehouse</a
            >
            <div class="dropdown-menu reports-dropdown" aria-labelledby="warehousesDropdown">
                <a class="dropdown-item" [routerLink]="['landing']">
                    <div class="dropdown-item-wrapper"><i class="icon-view_all" aria-hidden="true"></i><span>All warehouses</span></div>
                </a>
                <ng-template ngFor let-wh [ngForOf]="warehouses">
                    <a class="dropdown-item" *ngIf="wh.warehouse !== warehouse.warehouse" (click)="changeWarehouse(wh)">
                        <div class="dropdown-item-wrapper">
                            <i class="fa fa-share" aria-hidden="true"></i><span>{{ wh.displayName }}</span>
                        </div>
                    </a>
                </ng-template>
            </div>
        </li>
        <li class="nav-item item-2">
            <a class="nav-link" (click)="logoutButtonClick()"><i class="icon-close_session"></i><span> &nbsp;Sign Out</span></a>
        </li>
    </ul>
    <div style="color: #95a6a7" class="float-end px-4 pb-3">
        <small>v.{{ version }}</small>
    </div>
    <div style="color: #95a6a7" class="float-end px-4 pb-3" *ngIf="mapVersion">
        <small>Map: {{ mapVersion }}</small>
    </div>
</div>

<app-modal-centered
    #refreshMapModal
    key="refreshMapModal"
    title="Warehouse Map Updated"
    [extraSize]="'md'"
    [hideFooter]="'true'"
    (afterClose)="refreshMap(false)"
>
    <div class="row">
        <h5>Refresh Map</h5>
        <div class="col-12">
            <p>
                A new map has been uploaded in DesignTool for this Warehouse.<br /><br />
                Please press "Refresh map" now or wait <strong>{{ refreshMapSeconds }}</strong> seconds to use the latest map available.<br /><br />
                In case you want to update manually later, please press "Cancel" and remember to update at your convenience.<br /><br />
            </p>
        </div>
        <app-button [columns]="9" [extraClasses]="'float-end'" (clicked)="clearCacheCallback(true)"> Refresh Map </app-button>
        <app-button [columns]="3" [extraClasses]="'float-end'" data-bs-dismiss="modal">Cancel</app-button>
    </div>
</app-modal-centered>

