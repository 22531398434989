/* eslint-disable @typescript-eslint/member-ordering */
import { inject, Injectable } from '@angular/core';
import { debounceTime, Observable, ReplaySubject } from 'rxjs';
import * as _ from 'lodash-es';
import memoize from 'memoizee';
import { getEventTS, mapNotification } from '../mappers/notification.mapper';
import { WorkerMessageTypes } from '../web-workers/worker-message-types';
import { NotificationsMap } from '../notifications-map/notifications-map';
import { LinesService } from '@app/map/lines/shared/lines.service';
import { AlertsService } from '@alerts/shared/alerts.service';
import { CustomsService } from '@customs/shared/customs.service';
import { SignalREvents, SignalRServices, SignalRStatusClient } from '../clients/signalr-status.client';
import { VariableFilters } from '../events/variable-status';
import { VariablesService } from '@variables/shared/variables.service';
import { VariablesUrlFilter } from '@variables/shared/variable';
import { v4 as uuid } from 'uuid';

interface NotificationSubjects {
    [signalRService: string]: {
        [groupName: string]: {
            [eventName: string]: ReplaySubject<any>;
        };
    };
}

@Injectable({ providedIn: 'root' })
export class SignalRStatusService {
    private subjectStore: NotificationSubjects = {};
    private worker: Worker;
    handleReconnectionReference: () => Promise<void>;
    private readonly singalRClient = inject(SignalRStatusClient);
    private readonly lineService = inject(LinesService);
    private readonly alertService = inject(AlertsService);
    private readonly customService = inject(CustomsService);
    private readonly variableService = inject(VariablesService);
    private id: string;

    constructor() {
        this.id = uuid();
        this.startSubscription = memoize(this._startSubscription, {
            cacheKey: JSON.stringify,
            maxAge: 500
        });
    }

    //#region MainFunctionality
    public startSingleSubscription(groupName: string | VariableFilters, supportedEvents: Array<string>): Promise<(groupName: string | VariableFilters, eventName: string) => Observable<any>> {
        return this.startSubscription([[groupName, supportedEvents]]);
    }

    public startSubscription: (subscriptionDetails: Array<[groupName: string | VariableFilters, supportedEvents: Array<string>]>) => Promise<(groupName: string | VariableFilters, eventName: string) => Observable<any>>
    private _startSubscription(subscriptionDetails: Array<[groupName: string | VariableFilters, supportedEvents: Array<string>]>): Promise<(groupName: string | VariableFilters, eventName: string) => Observable<any>> {
        try {
            // Tip: Uncomment this once to force web worker to be cleaned, so it takes latest changes
            // this.terminateWorker();
            //this.initializeWorker();
            subscriptionDetails.forEach(([groupName, supportedEvents]) => {
                const groupNameAsString = this.groupNameToString(groupName);
                let signalRService = null;
                supportedEvents.forEach((eventName) => {
                    signalRService = this.getSignalRService(eventName as SignalREvents);
                    this.clearNotificationsMap(groupNameAsString, eventName);
                    this.subjectStore = {
                        ...this.subjectStore,
                        [signalRService]: {
                            ...(this.subjectStore[signalRService] || {}),
                            [groupNameAsString]: {
                                ...(this.subjectStore[signalRService]?.[groupNameAsString] || {}),
                                [eventName]: new ReplaySubject(Infinity, 10000), //(1, 10000) (100, 10000) (1, 3000) (Infinity, 10000) (Infinity, 5000)
                            },
                        },
                    };
                });
                this.singalRClient.subscribe$(
                    signalRService as SignalRServices,
                    groupNameAsString,
                    supportedEvents,
                    this.processNotification.bind(this));
            });
            return Promise.resolve((groupName, eventName) => {
                const groupNameAsString = this.groupNameToString(groupName);
                const signalRService = this.getSignalRService(eventName as SignalREvents);
                return this.subjectStore[signalRService][groupNameAsString][eventName].asObservable();
            });
        } catch (error) {
            console.error(error);
        }
    }

    public async endSingleSubscription(groupName: string | VariableFilters, supportedEvents: Array<SignalREvents>): Promise<void> {
        return this.endSubscription([[groupName, supportedEvents]]);
    }

    public async endSubscription(subscriptionDetails: Array<[groupName: string | VariableFilters, supportedEvents: Array<SignalREvents>]>): Promise<void> {
        subscriptionDetails.forEach(async ([groupName, supportedEvents]) => {
            const groupNameAsString = this.groupNameToString(groupName);
            const signalRService = this.getSignalRService(supportedEvents[0] as SignalREvents);
            try {
                supportedEvents.forEach((eventName) => {
                    if (this.subjectStore?.[signalRService]?.[groupNameAsString]?.[eventName]) {
                        this.subjectStore[signalRService][groupNameAsString][eventName].complete();
                        this.subjectStore[signalRService][groupNameAsString][eventName] = new ReplaySubject(Infinity, 10000);
                        delete this.subjectStore[signalRService][groupNameAsString][eventName];
                    }
                });
                if (!Object.keys(this.subjectStore[signalRService]?.[groupNameAsString] || {}).length && this.subjectStore[signalRService]?.[groupNameAsString]) {
                    this.subjectStore[signalRService][groupNameAsString] = null;
                    delete this.subjectStore[signalRService][groupNameAsString];
                }
                await this.singalRClient.unsubscribe$(signalRService, groupNameAsString, supportedEvents);
            } catch (error) {
                console.error(`${signalRService} ${error}`);
                console.error(error);
            }
        });
    }

    public terminateWorker() {
        this.worker?.terminate();
        this.worker = null;
    }

    private initializeWorker() {
        try {
            if (!this.worker && typeof Worker !== 'undefined') {
                // https://developer.mozilla.org/es/docs/Web/API/Web_Workers_API/Using_web_workers#creando_un_web_worker
                this.worker = new Worker(new URL('../web-workers/warehouse-status.worker', import.meta.url));
                // this.worker = new Worker(new URL('../web-workers/warehouse-status.worker' + '?' + Math.random(), import.meta.url));
                // onmessage listener will be called when warehouse-status.worker sends notifications through postMessage method
                this.worker.onmessage = async ({ data }) => {
                    if (data.messageType === WorkerMessageTypes.NOTIFY) {
                        await this.workerNofity({ signalRService: data.signalRService, groupName: data.groupName, eventName: data.eventName, notificationGroup: data.notificationGroup });
                    }
                };
                console.warn('Created warehouse-status.worker...', this.id);
            }
        } catch (error) {
            console.error(error);
            this.worker?.terminate();
            this.worker = null;
        }
    }

    private async workerNofity(data: { signalRService: SignalRServices, groupName: string; eventName: string; notificationGroup: any[] }) {
        const { signalRService, groupName, eventName, notificationGroup } = data;
        const replaySubject = this.subjectStore?.[signalRService]?.[groupName]?.[eventName];
        for (const notification of notificationGroup) {
            if (notification.state !== 'Auwa.Core.Model.Variables.AlarmVariable') {
                replaySubject?.next(notification);
            }
        }
    }

    private processNotification(groupName: string, eventName: string, notificationGroup: any) {
        try {
            const mainEvent = eventName.replace('initial', '');
            const signalRService = this.getSignalRService(mainEvent as SignalREvents);
            const mappedNotificationGroup =
                [
                    SignalREvents.ACTIVE_ALERTS_COUNT_CHANGED as string,
                    SignalREvents.REPORT_COUNT_CHANGED as string,
                    SignalREvents.CANONICAL_MAP_CHANGED as string,
                    SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED as string,
                    `initial${SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED}`,
                ]
                    .includes(eventName) ? [notificationGroup] : notificationGroup;
            if (this.worker) {
                this.worker.postMessage({
                    messageType: WorkerMessageTypes.FILTER_NOTIFICATIONS,
                    constructorName: this.constructor.name,
                    groupName,
                    eventName,
                    notificationGroup: mappedNotificationGroup,
                    signalRService,
                });
            } else {
                const replaySubject = this.subjectStore?.[signalRService]?.[groupName]?.[mainEvent];
                mappedNotificationGroup
                    .map((notification) => {
                        const mappedNotification = mapNotification(eventName, notification);
                        if (!mappedNotification && !mappedNotification?.floorId && !mappedNotification?.length && !mappedNotification?.[0]?.floorId)
                            console.error(`Wrong mapping for notification ${eventName}: ${JSON.stringify(notification)} ${JSON.stringify(mappedNotification[0])}`);
                        return mappedNotification;
                    })
                    .flat()
                    .filter((notification: any) => this.isNewer(groupName, mainEvent, notification))
                    .forEach((notification) => replaySubject?.next(notification));
            }
        } catch (error) {
            console.error(error)
        }
    }

    public getOnReconnectSubject(): Observable<void> {
        return this.singalRClient.onReconnect.pipe(debounceTime(5000));
    }

    private groupNameToString(groupName: string | VariableFilters): string {
        if (groupName instanceof VariableFilters) {
            return groupName.stringify();
        }
        return groupName;
    }

    public async applyFilter(oldGroupName: string, newGroupName: string, supportedEvents: Array<SignalREvents>): Promise<(groupName: string | VariableFilters, eventName: string) => Observable<any>>;
    public async applyFilter(oldGroupName: VariableFilters, newGroupName: VariableFilters, supportedEvents: Array<SignalREvents>): Promise<(groupName: string | VariableFilters, eventName: string) => Observable<any>>;
    public async applyFilter(oldGroupName: string | VariableFilters, newGroupName: string | VariableFilters, supportedEvents: Array<SignalREvents>): Promise<(groupName: string | VariableFilters, eventName: string) => Observable<any>> {
        const newGroupNameAsString = this.groupNameToString(newGroupName);
        let oldGroupNameAsString = null;
        if (oldGroupName) {
            oldGroupNameAsString = this.groupNameToString(oldGroupName);
            supportedEvents.map(async (eventName) => NotificationsMap.clearNotificationState(this.constructor.name, oldGroupNameAsString, eventName));
            try {
                await this.endSubscription([[oldGroupNameAsString, supportedEvents]]);
            } catch (error) {
                console.warn(`Unable to LeaveGroup ${oldGroupNameAsString}`);
            }
        }
        return this.startSubscription([[newGroupNameAsString, supportedEvents]]);
    }

    public async pauseNotifications(groupName: string, supportedEvents: Array<SignalREvents>);
    public async pauseNotifications(groupName: VariableFilters, supportedEvents: Array<SignalREvents>);
    public async pauseNotifications(groupName: string | VariableFilters, supportedEvents: Array<SignalREvents>) {
        const groupNameAsString = this.groupNameToString(groupName);
        supportedEvents.map(async (eventName) => NotificationsMap.clearNotificationState(this.constructor.name, groupNameAsString, eventName));
        try {
            await this.endSubscription([[groupNameAsString, supportedEvents]]);
        } catch (error) {
            console.warn(`Unable to LeaveGroup ${groupNameAsString}`);
        }
    }
    //#endregion

    //#region HelperMethods
    private clearNotificationsMap(groupName: string, eventName: string);
    private clearNotificationsMap(groupName: VariableFilters, eventName: string);
    private clearNotificationsMap(groupName: string | VariableFilters, eventName: string) {
        const groupNameAsString = this.groupNameToString(groupName);
        if (this.worker) {
            const constructorName = this.constructor.name;
            this.worker.postMessage({ messageType: WorkerMessageTypes.CLEAR_MAP, constructorName, groupName: groupNameAsString, eventName });
        } else {
            this.clearNotifications(groupNameAsString, eventName);
        }
    }

    private isNewer(groupName: string, eventName: string, event: any, timestampToCompare: any = null): boolean {
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        // So for mapping purpouses there is a event called initialLineChangeState, but this not applies to check if a notification is newer
        const parsedEventName = eventName.replace('initial', '');
        const notificationTS = timestampToCompare ?? getEventTS(parsedEventName, event);
        return NotificationsMap.isNewerNotification(this.constructor.name, groupName, parsedEventName, notificationTS, event);
    }

    public clearNotifications(groupName: string, eventName: string) {
        NotificationsMap.clearNotificationState(this.constructor.name, groupName, eventName);
    }

    private getSignalRService(eventName: SignalREvents) {
        return this.singalRClient.getSignalRService(eventName);
    }
    //#endregion

    //#region InitialLoadMethods
    public getLineStatusUsingWorker(groupName: string, floorId: string, areaId?: string, zoneId?: string, lineId?: string): void {
        const { LINE_STATE_CHANGED, EQUIPMENT_STATE_CHANGED } = SignalREvents;
        let eventName = !lineId ? LINE_STATE_CHANGED : EQUIPMENT_STATE_CHANGED;
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        this.lineService.getLineStatus(floorId, areaId, zoneId, lineId)
            .subscribe({
                next: (notification) => this.processNotification(groupName, `initial${eventName}`, notification),
                error: (error) => console.error(error)
            });
    }

    public getCustomStatusUsingWorker(groupName: string, customId: string): void {
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        const eventName = `initial${SignalREvents.LINE_STATE_CHANGED}`;
        this.customService.getCustomStatus(customId)
            .subscribe({
                next: (notification) => this.processNotification(groupName, eventName, notification),
                error: (error) => console.error(error)
            });
    }

    public getAlertsUsingWorker(groupName: string, floorId?: string, areaId?: string, zoneId?: string, lineId?: string): void {
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        // const eventName = `initial${ALERT_STATE_CHANGED}`;
        const eventName = SignalREvents.ALERT_STATE_CHANGED;
        this.alertService.getActiveAlerts(floorId, areaId, zoneId, lineId)
            .subscribe({
                next: (notification) => this.processNotification(groupName, eventName, notification),
                error: (error) => console.error(error)
            });
    }

    public getCustomAlertsUsingWorker(groupName: string, customId: string): void {
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        const eventName = `initial${SignalREvents.ALERT_STATE_CHANGED}`;
        this.alertService.getAlertsByCustom(customId)
            .subscribe({
                next: (notification) => this.processNotification(groupName, eventName, notification),
                error: (error) => console.error(error)
            });
    }

    public getVariablesUsingWorker(groupName: string, filter: VariablesUrlFilter): void {
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        const eventName = `initial${SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED}`;
        this.variableService.getInitialLoadVariables(filter)
            .subscribe({
                next: (notification) => this.processNotification(groupName, eventName, notification),
                error: (error) => console.error(error)
            });
    }

    public getLineStatusVariableLinesUsingWorker(groupName: string, floorId: string, areaId?: string, zoneId?: string, lineId?: string): void {
        const eventName = `initial${SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED}`;
        this.lineService.getLineStatusVariableLines(floorId, areaId, zoneId, lineId)
            .subscribe({
                next: (notification) => this.processNotification(groupName, eventName, notification),
                error: (error) => console.error(error)
            });
    }

    public getCustomStatusVariablesUsingWorker(groupName: string, mapId: string) {
        const eventName = `initial${SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED}`;
        this.customService.getCustomStatusVariables(mapId)
            .subscribe({
                next: (notification) => this.processNotification(groupName, eventName, notification),
                error: (error) => console.error(error)
            });
    }
    //#endregion
}
