import { Directive, Input, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[auwaOpenNewTab]',
    standalone: true,
})
export class OpenLinkInNewTabDirective {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('routerLink') link: string[];

    private readonly el = inject(ElementRef);
    private win: Window | null = inject(Window, { optional: true });

    constructor() { }

    @HostListener('mousedown', ['$event'])
    onLinkClicked(event) {
        if (event.ctrlKey) {
            this.win.open(this.link.join('/') || 'main/default');
        }
    }
}
