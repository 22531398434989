"use strict";

var isCallable = require("./is-callable");
module.exports = function (stringifiable) {
  try {
    if (stringifiable && isCallable(stringifiable.toString)) return stringifiable.toString();
    return String(stringifiable);
  } catch (e) {
    throw new TypeError("Passed argument cannot be stringifed");
  }
};