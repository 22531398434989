import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { Level } from '@app/notifications/shared/events/variable-status';
import { Kpis } from '@app/shared/models/kpis';
import { Observable, of } from 'rxjs';
import { catchError, map, retry, switchMap, take } from 'rxjs/operators';
import { EquipmentVariable } from './equipment-variable';

@Injectable({ providedIn: 'root' })
export class EquipmentsService {
    baseUrl: string;
    warehouse: string;

    private readonly http = inject(HttpClient);
    private readonly appLoadService = inject(AppLoadService);

    constructor() { }

    getEquipmentNames(equipmentType?: string, floorId?: string, areaId?: string, zoneId?: string): Observable<Array<string>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                if (res.warehouse && res.displayName) {
                    this.baseUrl = `${res.hostName}/api`.toLowerCase();
                    this.warehouse = res.warehouse;
                    let endpoint = `map/${this.warehouse}/equipments`;
                    // endpoint = equipmentType ? `${endpoint}?equipmentType=${equipmentType}` : endpoint;
                    if (equipmentType || floorId || areaId || zoneId) {
                        endpoint += `?`;
                        endpoint += equipmentType ? `equipmentType=${equipmentType}&` : '';
                        endpoint += floorId && floorId !== 'All' ? `floorId=${floorId}&` : '';
                        endpoint += areaId ? `areaId=${areaId}&` : '';
                        endpoint += zoneId ? `zoneId=${zoneId}&` : '';
                    }
                    return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
                }
            }),
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar los nombres de equipos', err);
                return of(null);
            }),
            // shareReplay()
        );
    }

    getEquipmentLocation(equipment): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/v2`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `equipments/${this.warehouse}/${equipment}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar la planta', err);
                return of(null);
            }),
            //shareReplay(),
        );
    }

    getEquipmentNamesByLine(idFloor: string, idArea: string, idZone: string, idLine: string): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = `map/${this.warehouse}/definitions/floors/${idFloor}/areas/${idArea}/zones/${idZone}/lines/${idLine}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                const equipmentNames: Array<string> = [];
                resp.equipments?.forEach((equipment) => {
                    if (equipment.type !== 'end') {
                        equipmentNames.push(`${equipment.id}-${equipment.type}`);
                    }
                });
                return equipmentNames;
            }),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar los nombres de equipos`, err);
                return of(null);
            }),
            //shareReplay(),
        );
    }

    // tslint:disable-next-line:max-line-length
    getVariablesFromEquipment(
        idFloor: string,
        idArea: string,
        idZone: string,
        idLine: string,
        idEquipment: string,
        type: string,
    ): Observable<EquipmentVariable[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/v2`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = `equipments/${this.warehouse}/floors/${idFloor}/areas/${idArea}/zones/${idZone}/lines/${idLine}/equipmentids/${idEquipment}/equipmenttypes/${type}/variables`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                const variables = resp?.notifications || [];
                const equipmentVariables: EquipmentVariable[] = variables.map((v) => {
                    return {
                        name: v.variableName.split('.').pop(),
                        value: v.value,
                        valueType: v.valueType,
                        datatype: v.valueType,
                        source: v.source,
                        sourceTimeStamp: v.sourceTimeStamp,
                        type: v.variableType,
                        isOpcError: v.isOpcError,
                        opcErrorType: v.isOpcError,
                        equipmentType: v.equipmentType,
                    };
                });
                return equipmentVariables;
            }),
            //shareReplay(),
        );
    }

    getKpisFromEquipment(idFloor: string, idArea: string, idZone: string, idLine: string, idEquipment: string): Observable<Kpis[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = `kpis/${this.warehouse}-${idFloor}-${idArea}-${idZone}-${idLine}-${idEquipment}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            //shareReplay(),
        );
    }

    getEquipmentTypes(): Observable<string[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `designtool/canonical/${this.warehouse}/equipmenttypes`;
                return this.http.get<string[]>(`${this.baseUrl}/${endpoint}`);
            }),
            //shareReplay(1, 1000),
        );
    }

    getEquipmentPhotoAndMetadata(type, level: string = Level.Equipment): Observable<string[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `designtool/ar-images/${level}/${this.warehouse}/typologies/${type}`;
                return this.http.get<string[]>(`${this.baseUrl}/${endpoint}`);
            }),
            catchError((err) => {
                console.warn('Ha ocurrido un problema al recuperar Photo y Metadata de ', type, err);
                return of(null);
            }),
            retry(1),
            //shareReplay(1, 1000),
        );
    }
}
