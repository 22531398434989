/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
    Variable,
    VariableType,
    isActiveVariable,
    VariableHistory,
    getVariableType,
    getVariableValue,
    isRunVariable,
    compareType,
} from '@variables/shared/variable';
import { EquipmentVariable } from '@app/map/equipments/equipment-variable';
import { ColorsService } from '../services/colors.service';

const { COMMAND, ALARM, WARNING, STATUS } = VariableType;

@Pipe({
    name: 'variableTypeIcon',
    standalone: true,
})
export class VariableTypeIconPipe implements PipeTransform {
    icon: string;

    private readonly colorsService = inject(ColorsService);
    private readonly sanitizer = inject(DomSanitizer);

    colorBoxes: string = this.colorsService.getColor('status-boxes'); //'#8B572A';
    colorRunning: string = this.colorsService.getColor('status-running'); //'#2da45d';.
    colorLWDO: string = this.colorsService.getColor('status-lwdo'); //'#a938cc';
    colorWarning: string = this.colorsService.getColor('status-warning'); //'#a938cc';
    colorErrors: string = this.colorsService.getColor('status-errors'); //'#FE840E';
    colorCritical: string = this.colorsService.getColor('status-critical'); //'#ff2800';
    colorStatus: string = this.colorsService.getColor('status-status'); //'#17a2b8';

    constructor() { }

    transform(variable: Variable | VariableHistory | EquipmentVariable, variableName?: string): SafeHtml {
        const icon = compareType(variable, COMMAND)
            ? 'fa-cog'
            : isRunVariable(variable)
                ? 'fa-play'
                : compareType(variable, STATUS)
                    ? 'fa-file'
                    : compareType(variable, ALARM)
                        ? 'fa-exclamation-circle'
                        : 'fa-exclamation-triangle';
        const value = getVariableValue(variable);
        const iconColor = !isActiveVariable(value)
            ? ''
            : isRunVariable(variable)
                ? this.colorRunning
                : compareType(variable, STATUS)
                    ? this.colorStatus
                    : compareType(variable, COMMAND)
                        ? '#6610f2'
                        : compareType(variable, WARNING)
                            ? this.colorWarning
                            : compareType(variable, ALARM)
                                ? variable.equipmentType?.toUpperCase() === 'EMERGENCY'
                                    ? this.colorCritical
                                    : this.colorErrors
                                : '';

        const variableType = getVariableType(variable);
        const pascalCaseType = variableType.charAt(0).toUpperCase() + variableType.slice(1);
        const title = `Variable type: ${pascalCaseType}`;
        const htmlIcon = `<i aria-hidden="true" class="fa ${icon}" style="color: ${iconColor}" [title]="${title}"></i>`;
        const htmlName = variableName || '';
        return this.sanitizer.bypassSecurityTrustHtml(`${htmlIcon}&nbsp&nbsp;${htmlName}`);
    }
}
/*
const getVariableType = (variable: Variable | VariableHistory | EquipmentVariable): string =>
    (variable as Variable | VariableHistory).variableType || (variable as EquipmentVariable).type;

const getVariableValue = (variable: Variable | VariableHistory | EquipmentVariable): string =>
    (variable as Variable).variableValue || (variable as VariableHistory | EquipmentVariable).value;

function isRunVariable(variable) {
    const name: string = (variable as Variable | VariableHistory).variableName ?? (variable as EquipmentVariable).name;
    return name.toLowerCase() === 'run' && compareType(variable, STATUS);
}

function compareType(variable: Variable | VariableHistory | EquipmentVariable, type: VariableType) {
    const variableType = getVariableType(variable);
    return variableType.toLowerCase() === type.toLowerCase();
}*/
