"use strict";

var toPosInt = require("../number/to-pos-integer");
var test = function (arg1, arg2) {
  return arg2;
};
var desc, defineProperty, generate, mixin;
try {
  Object.defineProperty(test, "length", {
    configurable: true,
    writable: false,
    enumerable: false,
    value: 1
  });
} catch (ignore) {}
if (test.length === 1) {
  // ES6
  desc = {
    configurable: true,
    writable: false,
    enumerable: false
  };
  defineProperty = Object.defineProperty;
  module.exports = function (fn, length) {
    length = toPosInt(length);
    if (fn.length === length) return fn;
    desc.value = length;
    return defineProperty(fn, "length", desc);
  };
} else {
  mixin = require("../object/mixin");
  generate = function () {
    var cache = [];
    return function (length) {
      var args,
        i = 0;
      if (cache[length]) return cache[length];
      args = [];
      while (length--) args.push("a" + (++i).toString(36));
      // eslint-disable-next-line no-new-func
      return new Function("fn", "return function (" + args.join(", ") + ") { return fn.apply(this, arguments); };");
    };
  }();
  module.exports = function (src, length) {
    var target;
    length = toPosInt(length);
    if (src.length === length) return src;
    target = generate(length)(src);
    try {
      mixin(target, src);
    } catch (ignore) {}
    return target;
  };
}