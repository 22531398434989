// Call dispose callback on each cache purge

"use strict";

var callable = require("es5-ext/object/valid-callable"),
  forEach = require("es5-ext/object/for-each"),
  extensions = require("../lib/registered-extensions"),
  apply = Function.prototype.apply;
extensions.dispose = function (dispose, conf, options) {
  var del;
  callable(dispose);
  if (options.async && extensions.async || options.promise && extensions.promise) {
    conf.on("deleteasync", del = function (id, resultArray) {
      apply.call(dispose, null, resultArray);
    });
    conf.on("clearasync", function (cache) {
      forEach(cache, function (result, id) {
        del(id, result);
      });
    });
    return;
  }
  conf.on("delete", del = function (id, result) {
    dispose(result);
  });
  conf.on("clear", function (cache) {
    forEach(cache, function (result, id) {
      del(id, result);
    });
  });
};