import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
    name: 'minutes',
    standalone: true,
})
export class MinutesPipe implements PipeTransform {
    @memo({
        resolver: (minutes) => ({ minutes }),
        cache: new WeakMap(),
    })
    transform(minutes: number) {
        if (minutes > 59) {
            const hours = Math.floor(minutes / 60);
            const minutesRemainder = Math.floor(minutes % 60);
            const minutesRest = (minutes % 60) - minutesRemainder;
            const secondsRemainder = Math.floor(minutesRest * 60);
            if (hours > 23) {
                const days = Math.floor(hours / 24);
                const hoursRemainder = hours % 24;
                return `${days}d${hoursRemainder}h${minutesRemainder}m${secondsRemainder}s`;
            } else {
                return `${hours}h${minutesRemainder}m${secondsRemainder}s`;
            }
        } else {
            const minutesRemainder = Math.floor(minutes % 60);
            const minutesRest = (minutes % 60) - minutesRemainder;
            const secondsRemainder = Math.floor(minutesRest * 60);
            return `${minutesRemainder}m${secondsRemainder}s`;
        }
    }
}
