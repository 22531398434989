import { APP_INITIALIZER, ErrorHandler, ApplicationConfig, enableProdMode } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { HttpErrorInterceptor } from '@ays';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication } from '@azure/msal-browser';
import { SettingsService } from './core/shared/settings/settings.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AppLoadService } from './app-load.service';
import { APPLICATION_ROUTES } from './app.routing';
import { CachingInterceptor } from './core/interceptors/caching-interceptor';
import { MonitoringErrorHandler } from './shared/services/monitoring.error.handler';
import { environment } from '../environments/environment';
import { HttpErrorResponseInterceptor } from './core/interceptors/http-error-interceptor';
//import { ServiceLocator } from './shared/services/service-locator.service';
import { provideMarkdown } from 'ngx-markdown';

function initApp(firstLoadService: AppLoadService) {
    return () => firstLoadService.initialize();
}

function getConfig(settingsService: SettingsService): IPublicClientApplication {
    return settingsService.getMsalConfig();
}
function getInterceptorConfig(settingsService: SettingsService): MsalInterceptorConfiguration {
    return settingsService.getMsalInterceptorConfig();
}
function getGuardConfig(settingsService: SettingsService): MsalGuardConfiguration {
    return settingsService.getmsalGuardConfig();
}

function appInsightsFactory(settingsService: SettingsService): ApplicationInsights {
    let appInsightsInstrumentationKey: string = undefined;

    return new ApplicationInsights({
        config: {
            instrumentationKey: appInsightsInstrumentationKey,
        },
    });
}

if (environment.production) {
    enableProdMode();
}

export const appConfig: ApplicationConfig = {
    providers: [
        //importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })),
        // importProvidersFrom(
        //     ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        //     ServiceWorkerModule.register('ngsw-worker.js', {
        //         enabled: !isDevMode(),
        //         // Register the ServiceWorker as soon as the application is stable
        //         // or after 30 seconds (whichever comes first).
        //         registrationStrategy: 'registerWhenStable:30000',
        //     }),
        // ),
        // provideServiceWorker('ngsw-worker.js', {
        //     enabled: environment.production,
        //     // Register the ServiceWorker as soon as the application is stable
        //     // or after 30 seconds (whichever comes first).
        //     registrationStrategy: 'registerWhenStable:30000',
        // }),
        provideRouter(APPLICATION_ROUTES, withComponentInputBinding(), withRouterConfig({ paramsInheritanceStrategy: 'always' })),
        provideHttpClient(withInterceptorsFromDi()),
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: getConfig, deps: [SettingsService] },
        { provide: MSAL_GUARD_CONFIG, useFactory: getGuardConfig, deps: [SettingsService] },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: getInterceptorConfig, deps: [SettingsService] },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorResponseInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppLoadService], multi: true },
        { provide: ApplicationInsights, useFactory: appInsightsFactory },
        { provide: ErrorHandler, useClass: MonitoringErrorHandler },
        { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },
        MsalGuard,
        MsalService,
        MsalBroadcastService,
        provideMarkdown({ loader: HttpClient }),
        //provideAnimations()
        //ServiceLocator,
    ]
}
