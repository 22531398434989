"use strict";

var forEach = Array.prototype.forEach,
  create = Object.create;

// eslint-disable-next-line no-unused-vars
module.exports = function (arg /*, …args*/) {
  var set = create(null);
  forEach.call(arguments, function (name) {
    set[name] = true;
  });
  return set;
};