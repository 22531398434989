// Internal method, used by iteration functions.
// Calls a function for each key-value pair found in object
// Optionally takes compareFn to iterate object in specific order

"use strict";

var callable = require("./valid-callable"),
  value = require("./valid-value"),
  bind = Function.prototype.bind,
  call = Function.prototype.call,
  keys = Object.keys,
  objPropertyIsEnumerable = Object.prototype.propertyIsEnumerable;
module.exports = function (method, defVal) {
  return function (obj, cb /*, thisArg, compareFn*/) {
    var list,
      thisArg = arguments[2],
      compareFn = arguments[3];
    obj = Object(value(obj));
    callable(cb);
    list = keys(obj);
    if (compareFn) {
      list.sort(typeof compareFn === "function" ? bind.call(compareFn, obj) : undefined);
    }
    if (typeof method !== "function") method = list[method];
    return call.call(method, list, function (key, index) {
      if (!objPropertyIsEnumerable.call(obj, key)) return defVal;
      return call.call(cb, thisArg, obj[key], key, obj, index);
    });
  };
};