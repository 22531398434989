"use strict";

var toArray = require("es5-ext/array/to-array"),
  isValue = require("es5-ext/object/is-value"),
  callable = require("es5-ext/object/valid-callable");
var slice = Array.prototype.slice,
  resolveArgs;
resolveArgs = function (args) {
  return this.map(function (resolve, i) {
    return resolve ? resolve(args[i]) : args[i];
  }).concat(slice.call(args, this.length));
};
module.exports = function (resolvers) {
  resolvers = toArray(resolvers);
  resolvers.forEach(function (resolve) {
    if (isValue(resolve)) callable(resolve);
  });
  return resolveArgs.bind(resolvers);
};