// tslint:disable-next-line: max-line-length
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
    Output,
    EventEmitter,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    inject,
} from '@angular/core';
import { HostBinding } from '@angular/core';
import { PipesModule } from '@ays';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-input-2',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        PipesModule,
    ],
})
export class InputComponent2 implements OnInit, AfterViewInit, OnDestroy {
    @Input() type?: string;
    @Input() key: string;
    @Input() label: string;
    @Input() columns: number;
    @Input() extraClasses: number;
    @Input() autocomplete: Array<string>;
    @Input() placeholder?: string;
    @Output() selectAutocomplete = new EventEmitter();
    @HostBinding('class') classes;
    @ViewChild('input', { static: false }) input: ElementRef;
    @ViewChild('myAutocomplete') myAutocomplete: ElementRef;
    $input: JQuery;
    $myAutocomplete: JQuery;
    autocompleteFiltered = [];
    inputValue = '';

    private readonly changeDetectorRef = inject(ChangeDetectorRef);

    constructor() { }

    ngOnInit() {
        this.classes = `col-12 col-md-6 col-lg-${this.columns || 2} ${this.extraClasses}`;
    }
    ngAfterViewInit(): void {
        this.$input = $(this.input.nativeElement);
        this.$input
            .on('focus', () => {
                this.$input.parent().removeClass('input--filled').addClass('input--filled');
            })
            .on('blur', () => {
                if (this.$input.val()) {
                    this.$input.parent().removeClass('input--filled').addClass('input--filled');
                } else {
                    this.$input.parent().removeClass('input--filled');
                }
            });
        if (this.autocomplete) {
            this.$input.off('keyup.autocomplete').on('keyup.autocomplete', (e) => {
                const value = String($(e.target).val());
                if (value.length > 2) {
                    this.inputValue = value;
                    this.autocompleteFiltered = this.autocomplete.filter((item) => item.includes(value));
                    if (this.autocompleteFiltered.length > 0) {
                        this.$input.parent().removeClass('input--expanded').addClass('input--expanded');
                    } else {
                        this.$input.parent().removeClass('input--expanded');
                    }
                } else {
                    this.autocompleteFiltered = [];
                    this.$input.parent().removeClass('input--expanded');
                }
                this.changeDetectorRef.detectChanges();
            });
            this.$myAutocomplete = $(this.myAutocomplete.nativeElement);
            this.$myAutocomplete.off('click.autocomplete').on('click.autocomplete', (e) => {
                const $target = $(e.target).hasClass('highlight') ? $(e.target).parent() : $(e.target);
                const $value = $target.data('value');
                this.autocompleteFiltered = [];
                if ($value) {
                    this.$input.val('');
                    this.$input.parent().removeClass('input--expanded input--filled');
                    this.selectAutocomplete.emit($value);
                    this.changeDetectorRef.detectChanges();
                }
            });
        }
    }
    ngOnDestroy(): void {
        this.autocomplete = null;
        this.$input?.off();
        this.$myAutocomplete?.off();
    }
}
