import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VariableValueType } from '@app/notifications/shared/events/variable-status';
import memo from 'memo-decorator';

@Pipe({
    name: 'variableValueTypePipe',
    standalone: true,
})
export class VariableValueTypePipe implements PipeTransform {

    private readonly sanitizer = inject(DomSanitizer);

    constructor() { }

    @memo({
        resolver: (value) => ({ value }),
        cache: new WeakMap(),
    })
    transform(value: any = '', type = VariableValueType.String): any {
        switch (type) {
            case VariableValueType.Boolean:
                value = [undefined, null, ''].includes(value) ? '' : ['true', '1'].includes(value.toLowerCase()) ? 'True' : 'False';
                break;
        }
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
