import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { MsalService } from '@azure/msal-angular';
import { of } from 'rxjs';
import { AppLoadService } from '@app/app-load.service';


export const CanActivateRoute: CanActivateFn = (/*route: ActivatedRouteSnapshot, state: RouterStateSnapshot*/) => {
    const msalService = inject(MsalService);
    const router = inject(Router);
    const appLoadService = inject(AppLoadService);
    const authenticationService = inject(AuthenticationService);

    if (msalService.instance.getAllAccounts().length > 0) {
        let warehouse;
        appLoadService.getCurrentWarehouse.subscribe((res) => (warehouse = res));
        const isAuth = authenticationService.checkGroups(warehouse);
        if (isAuth) {
            return of(true);
        } else {
            // if (!appLoadService.rolesComplete) { //this is not in use
            //     window.location.reload();
            // }
            router.navigate(['/unauthorized']);
            return of(false);
        }
    } else {
        router.navigate(['/unauthorized']);
        return of(false);
    }
};