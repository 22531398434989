import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Structure } from '@home/shared/structure';
import { AppLoadService } from '@app/app-load.service';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HomeService {
    baseUrl: string;
    warehouse: string;

    private readonly http = inject(HttpClient);
    private readonly appLoadService = inject(AppLoadService);

    constructor() { }

    getStructure(): Observable<Structure> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `map/${this.warehouse}`;
                return this.http.get<Structure>(`${this.baseUrl}/${endpoint}`);
            }),
            //shareReplay(),
        );
    }
}
