"use strict";

var safeToString = require("./safe-to-string");
var reNewLine = /[\n\r\u2028\u2029]/g;
module.exports = function (value) {
  var string = safeToString(value);
  // Trim if too long
  if (string.length > 100) string = string.slice(0, 99) + "…";
  // Replace eventual new lines
  string = string.replace(reNewLine, function (char) {
    return JSON.stringify(char).slice(1, -1);
  });
  return string;
};