"use strict";

var toPosInt = require("es5-ext/number/to-pos-integer");
module.exports = function (optsLength, fnLength, isAsync) {
  var length;
  if (isNaN(optsLength)) {
    length = fnLength;
    if (!(length >= 0)) return 1;
    if (isAsync && length) return length - 1;
    return length;
  }
  if (optsLength === false) return false;
  return toPosInt(optsLength);
};