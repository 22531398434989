import { SignalREvents } from '../clients/signalr-status.client';
import { alertsActiveStatusMapper, alertsActiveCountMapper, initialAlertsActiveStatusMapper } from './alertsActive-status.mapper';
import { equipmentStatusMapper, initialEquipmentStatusMapper } from './equipment-status.mapper';
import { lineStatusMapper } from './line-status.mapper';
import { variablesStatusMapper, initialVariablesStatusMapper} from './variable-status.mapper';
import canonicalMapChangedMapper from './service-status.mapper';
import { reportCountChangedMapper } from './report-count.mapper';

const {
    ACTIVE_ALERTS_COUNT_CHANGED,
    ALERT_STATE_CHANGED,
    LINE_STATE_CHANGED,
    EQUIPMENT_STATE_CHANGED,
    WAREHOUSE_STATUS_VARIABLE_CHANGED,
    CANONICAL_MAP_CHANGED,
    REPORT_COUNT_CHANGED
} = SignalREvents;

export const mapNotification = (eventName: string, event: any): any => {
    const isParsed = [
        ALERT_STATE_CHANGED, `initial${ALERT_STATE_CHANGED}`,
        LINE_STATE_CHANGED, `initial${LINE_STATE_CHANGED}`,
        EQUIPMENT_STATE_CHANGED, `initial${EQUIPMENT_STATE_CHANGED}`,
        WAREHOUSE_STATUS_VARIABLE_CHANGED, `initial${WAREHOUSE_STATUS_VARIABLE_CHANGED}`,
        ACTIVE_ALERTS_COUNT_CHANGED,
        CANONICAL_MAP_CHANGED,
        REPORT_COUNT_CHANGED,
    ].includes(eventName);
    if (!isParsed) throw new Error(`Mapper not configured for ${eventName}: ${event}`);
    /* eslint-disable prettier/prettier */
     const eventMapper = {
        [ALERT_STATE_CHANGED]:                           alertsActiveStatusMapper,
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        [`initial${ALERT_STATE_CHANGED}`]:               initialAlertsActiveStatusMapper,
        [ACTIVE_ALERTS_COUNT_CHANGED]:                   alertsActiveCountMapper,
        [LINE_STATE_CHANGED]:                            lineStatusMapper,
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        [`initial${LINE_STATE_CHANGED}`]:                lineStatusMapper, //initialLineStatusMapper,
        [EQUIPMENT_STATE_CHANGED]:                       equipmentStatusMapper,
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        [`initial${EQUIPMENT_STATE_CHANGED}`]:           initialEquipmentStatusMapper,
        [ WAREHOUSE_STATUS_VARIABLE_CHANGED]:            variablesStatusMapper,
        // Unlinke SignalR notifications, the initial load had some differences on the property naming (ie: SignalR floorid is floorId in the initial load)
        // [`initial${WAREHOUSE_STATUS_VARIABLE_CHANGED}`]: (event) => [initialVariablesStatusMapper(event)],
        // [`initial${WAREHOUSE_STATUS_VARIABLE_CHANGED}`]: (notification) => ([initialVariablesStatusMapper(notification)]),
        [`initial${WAREHOUSE_STATUS_VARIABLE_CHANGED}`]: initialVariablesStatusMapper,
        [CANONICAL_MAP_CHANGED]:                         canonicalMapChangedMapper,
        [REPORT_COUNT_CHANGED]:                          reportCountChangedMapper,
     };
    // if (eventName == `initial${SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED}`)
    //     console.log('map', eventMapper[eventName](event))
    /* eslint-enable prettier/prettier */
    return eventMapper[eventName](event);
};

export const getEventTS = (eventName: string, event: any) => {
    /* eslint-disable prettier/prettier */
    return eventName === ALERT_STATE_CHANGED               ? event.serverts :
           eventName === EQUIPMENT_STATE_CHANGED           ? event.serverts :
           eventName === LINE_STATE_CHANGED                ? event.serverts :
           eventName === WAREHOUSE_STATUS_VARIABLE_CHANGED ? event.serverTimeStamp
                                                           : event.serverts;
    /* eslint-enable prettier/prettier */
};
