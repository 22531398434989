import { Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { combineLatestWith } from 'rxjs/operators';

export interface ColorStructure {
    styleId: string;
    color: string;
}

@Injectable({ providedIn: 'root' })
export class ColorsService {
    private colors: ColorStructure[] = [
        //{ styleId: 'default-status-boxes', color: '#8b572a' },
        { styleId: 'status-boxes', color: '#8b572a' },
        { styleId: 'status-starts', color: '#fcde82' },
        { styleId: 'status-running', color: '#2da45d' },
        { styleId: 'status-on', color: '#2da45d' },
        { styleId: 'status-status', color: '#17a2b8' },
        { styleId: 'status-warning', color: '#fcde82' },
        { styleId: 'status-errors', color: '#fe840e' },
        { styleId: 'status-critical', color: '#ff2800' },
        { styleId: 'status-disconnected', color: '#949494' },
        { styleId: 'status-no-data', color: '#eee' },
        { styleId: 'status-lwdo', color: '#a938cc' },
        { styleId: 'status-waiting', color: '#2a3a4a' },
    ];

    constructor() { }

    getColors(): ColorStructure[] {
        return this.colors;
    }

    getColor(styleId: string): string {
        return this.colors.find((s) => s.styleId === styleId)?.color || '';
    }

    setColor(styleId: string, color: string) {
        const colorToSet = this.colors.findIndex((s) => s.styleId === styleId);
        this.colors[colorToSet].color = color;
    }
}
