import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { Structure } from '@app/map/home/shared/structure';
import { EquipmentStatusNotification } from '@app/notifications/shared/events/equipment-status';
import { LineStatusNotification } from '@app/notifications/shared/events/line-status';
import { VariableNotification } from '@app/notifications/shared/events/variable-status';
import { Kpis } from '@app/shared/models/kpis';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LinesService {
    baseUrl: string;
    warehouse: string;

    private readonly http = inject(HttpClient);
    private readonly appLoadService = inject(AppLoadService);

    constructor() { }

    getLinesNames(): Observable<string[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                if (res.warehouse && res.displayName) {
                    this.baseUrl = `${res.hostName}/api`.toLowerCase();
                    this.warehouse = res.warehouse;
                    const endpoint = `map/${this.warehouse}/lines`;
                    return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
                }
            }),
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar los nombres de lineas de equipos', err);
                return of(null);
            }),
        );
    }

    getLine(floorId: string, areaId: string, zoneId: string, lineId: string): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res: any) => {
                const baseUrl = `${res.hostName}/api`.toLowerCase();
                const warehouse = res.warehouse;
                const endpoint = `map/${warehouse}/definitions/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}`;
                return this.http.get<Structure>(`${baseUrl}/${endpoint}`);
            }),
            map((resp) => {
                return resp || null;
            }),
            take(1),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar la linea de equipos -> ${err}`);
                return of(null);
            }),
        );
    }

    getLineStatus(floorId: string, areaId?: string, zoneId?: string, lineId?: string): Observable<Array<LineStatusNotification | EquipmentStatusNotification>> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = !!lineId ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}` :
                                 !!zoneId ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}` :
                                 !!areaId ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}`
                                          : `V2/state/${this.warehouse}/floors/${floorId}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado de la linea de equipos -> ${err}`);
                return of([]);
            }),
        );
    }

    getLineStatusVariableLines(floorId: string, areaId?: string, zoneId?: string, lineId?: string): Observable<VariableNotification[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = !!lineId ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}` :
                                 !!zoneId ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}` :
                                 !!areaId ? `V2/state/${this.warehouse}/floors/${floorId}/areas/${areaId}`
                                          : `V2/state/${this.warehouse}/floors/${floorId}`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}/variable-lines`);
            }),
            take(1),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado de la linea de equipos -> ${err}`);
                return of([]);
            }),
        );
    }

    getLineKpis(floorId: string, areaId: string, zoneId: string, lineId: string): Observable<Kpis> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                // tslint:disable-next-line:max-line-length
                const endpoint = lineId && lineId !== '' ? `kpis/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}` :
                    zoneId && zoneId !== '' ? `kpis/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}` :
                        areaId && areaId !== '' ? `kpis/${this.warehouse}/floors/${floorId}/areas/${areaId}` :
                            floorId && floorId !== '' ? `kpis/${this.warehouse}/floors/${floorId}`
                                : 'kpis';
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            take(1),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar los KPI's de la linea de equipos -> ${err}`);
                return of(null);
            }),
        );
    }

    lineNotificationReceieved(notificacion: any): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `state/${this.warehouse}/notifications/notificationReceived`;
                return this.http.post<any>(`${this.baseUrl}/${endpoint}`, notificacion);
            }),
            map((resp) => {
                return resp;
            }),
            take(1),
            catchError((err) => {
                console.error(`Ha ocurrido un problema al mandar la rececpción de una notificación de la linea -> ${err}`);
                return of(null);
            }),
        );
    }

    getIcons(): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                if (res.warehouse && res.displayName) {
                    this.baseUrl = `${res.hostName}/api`.toLowerCase();
                    this.warehouse = res.warehouse;
                    const endpoint = `designtool/typologies/${this.warehouse}/icons`;
                    return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
                }
            }),
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar los nombres de lineas de equipos', err);
                return of(null);
            }),
        );
    }
}
