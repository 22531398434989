import { ChangeDetectionStrategy, Component, Input, HostBinding, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-select-virtual',
    templateUrl: './select-virtual.component.html',
    styleUrls: ['./select-virtual.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgSelectModule],
})
export class SelectVirtualComponent implements OnInit, OnDestroy {
    @Input() key: string;
    @Input() item$ = [];
    @Input() columns: number;
    @Input() extraClasses?: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() searchable: boolean;
    @Input() disabled: boolean;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() search: EventEmitter<any> = new EventEmitter();
    @HostBinding('class') classes;
    visibleOptions = 10;
    placeholderBackup = '';

    constructor() {}

    ngOnInit() {
        this.classes = `col-12 col-md-6 col-lg-${this.columns || 2} ${this.extraClasses}`;
    }

    ngOnDestroy(): void {
        this.item$ = null;
        this.change?.next(true);
        this.change?.complete();
        this.search?.next(true);
        this.search?.complete();
    }

    onChange($event) {
        this.change.emit($event);
    }

    onSearch($event) {
        this.search.emit($event);
    }

    onFocus($event) {
        this.placeholderBackup = this.placeholder;
        this.placeholder = '';
    }

    onBlur($event) {
        this.placeholder = this.placeholderBackup;
        this.placeholderBackup = '';
    }
}
