/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, Output, EventEmitter, OnChanges, ViewChild, ElementRef, inject } from '@angular/core';
import { EquipmentVariable } from '@app/map/equipments/equipment-variable';
import { VariableType, isActiveVariable, Variable, VariableValueBody, VariableValueResponse } from '@variables/shared/variable';
import { VariablesService } from '@variables/shared/variables.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { VariableValueType } from '@app/notifications/shared/events/variable-status';
import { VariableValueTypePipe } from '../../pipes/variable-value-type.pipe';
import { AysCommonsModule } from '@ays';
import { NgClass } from '@angular/common';

const { ALARM, WARNING, STATUS, COMMAND } = VariableType;

export enum ComponentTypes {
    RESET_BUTTON = 'ResetButton',
    INPUT = 'Input',
    SWITCH = 'Switch',
}

export enum InputState {
    UPDATED = 'Updated',
    DIRTY = 'Dirty',
    SAVING = 'Saving',
    EDITING = 'Editing',
    REFRESHING = 'Refreshing',
    REFRESHED = 'Refreshed',
}
export interface VariableInputState {
    value?: string;
    error?: boolean;
    errorMessage?: string;
    inputState?: Array<InputState>;
}

const { UPDATED, DIRTY, SAVING, EDITING, REFRESHED, REFRESHING } = InputState;
const FAILED = 'Failed';
const getVariableValue = (variable: Variable | EquipmentVariable): string => (variable as Variable).variableValue || (variable as EquipmentVariable).value;
const getVariableName = (variable: Variable | EquipmentVariable): string => (variable as Variable).variableName || (variable as EquipmentVariable).name;
const getVariableType = (variable: Variable | EquipmentVariable): string => (variable as Variable).variableType || (variable as EquipmentVariable).type;

@Component({
    selector: 'shared-variable-input',
    templateUrl: './variable-input.component.html',
    styleUrls: ['./variable-input.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        AysCommonsModule,
        VariableValueTypePipe,
    ],
})
export class VariableInputComponent implements OnDestroy, OnChanges {
    @Input() fqn: string;
    @Input() variable: Variable | EquipmentVariable;
    @Input() variableState?: VariableInputState;
    @Input() refreshable = false;
    @Output() editValue = new EventEmitter();
    @Output() updateValue = new EventEmitter();
    @Output() undoEditValue = new EventEmitter();
    @Output() saveInput = new EventEmitter();
    @Output() focusLost = new EventEmitter();
    @Output() refreshValue = new EventEmitter();
    @ViewChild('variableInput') variableInput: ElementRef;
    isActiveVariable = isActiveVariable;
    variableValue: string;
    variableValueType: VariableValueType;
    variableName = '';
    variableType = '';
    componentType = ComponentTypes.INPUT;
    ALARM = ALARM;
    WARNING = WARNING;
    STATUS = STATUS;
    COMMAND = COMMAND;
    UPDATED = UPDATED;
    DIRTY = DIRTY;
    SAVING = SAVING;
    EDITING = EDITING;
    REFRESHED = REFRESHED;
    REFRESHING = REFRESHING;

    lastResetValueSend = '0';
    isResetting = false;

    private ngUnsubscribe: Subject<any> = new Subject();

    private readonly variableService = inject(VariablesService);

    constructor() { }

    ngOnDestroy(): void {
        this.variableInput = null;
        this.variableState = null;
        this.variable = null;
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        if (changes.variable?.currentValue) {
            const variable: Variable | EquipmentVariable = changes.variable.currentValue;
            this.variableValue = (variable as Variable).variableValue || (variable as EquipmentVariable).value;

            //while VariableValueType is capitalized
            const variableValueTypeTemp = (variable as Variable).variableValueType || (variable as EquipmentVariable).valueType;
            const variableValueTypeCapitalized = variableValueTypeTemp[0].toUpperCase() + variableValueTypeTemp.slice(1);
            this.variableValueType = VariableValueType[variableValueTypeCapitalized];

            this.variableName = (variable as Variable).variableName || (variable as EquipmentVariable).name;
            this.variableType = (variable as Variable).variableType || (variable as EquipmentVariable).type;
            const variableType = (this.variableType || '').toLowerCase();
            const valueType = (this.variableValueType || '').toLowerCase();

            this.componentType =
                variableType === COMMAND.toLowerCase() && this.variableName.toUpperCase().includes('RESET')
                    ? ComponentTypes.RESET_BUTTON
                    : variableType === COMMAND.toLowerCase() && valueType === VariableValueType.Boolean.toLowerCase()
                        ? ComponentTypes.SWITCH
                        : ComponentTypes.INPUT;
        }
    }

    editInputValue(event) {
        if (getVariableType(this.variable).toLowerCase() === COMMAND.toLowerCase()) {
            setTimeout(() => this.variableInput.nativeElement.focus());
            this.editValue.emit(
                this.generateState({
                    event,
                    includedStates: [EDITING],
                }),
            );
        }
    }

    updateInputValue(event) {
        const newValue = String(event.target.value);
        const currentValue = getVariableValue(this.variable);
        this.updateValue.emit(
            this.generateState({
                stateValue: newValue,
                includedStates: newValue !== currentValue ? [DIRTY] : [],
            }),
        );
    }

    undoInputChanges() {
        this.undoEditValue.emit(
            this.generateState({
                stateValue: null,
                excludedStates: [EDITING, DIRTY],
            }),
        );
    }

    isValidValue(valueType: VariableValueType, value: string): boolean {
        const numericRE = new RegExp('^$|^-?(\\d+)?([\\.\\,]?\\d*)?$');
        return (
            (valueType === VariableValueType.Boolean && ['0', '1', 'true', 'false'].includes(value.toLowerCase())) ||
            (valueType === VariableValueType.Numeric && numericRE.test(value)) ||
            valueType === VariableValueType.String
        );
    }

    saveInputValue() {
        const variableFqnToOpc = this.fqnToOpcFormat(this.fqn);
        const variableName = getVariableName(this.variable);
        const value = this.variableState.value.trim() || this.variableInput.nativeElement.value.trim();
        const variableValue = new VariableValueBody(variableName, value);
        this.saveInput.emit(
            this.generateState({
                excludedStates: [EDITING],
                includedStates: [SAVING],
            }),
        );

        const variableValueType: VariableValueType = VariableValueType[this.variableValueType];
        if (this.isValidValue(variableValueType, value)) {
            this.variableService
                .writeVariable(variableFqnToOpc, variableValue)
                //.pipe(takeUntil(this.ngUnsubscribe),
                .subscribe({
                    next: (result: VariableValueResponse) => {
                        if (FAILED === result.status) {
                            this.handleSaveError(result);
                        } else {
                            const currentVariableValue = result.response.currentVariableValue;
                            this.saveInput.emit(
                                this.generateState({
                                    newValue: currentVariableValue,
                                    stateValue: null,
                                    error: false,
                                    excludedStates: [DIRTY, SAVING],
                                    includedStates: [UPDATED],
                                }),
                            );
                        }
                    },
                    error: (error: string) => {
                        this.handleSaveError({
                            error,
                            status: '',
                            response: undefined,
                        })
                    }
                });
        } else {
            this.saveInput.emit(
                this.generateState({
                    stateValue: null,
                    error: true,
                    errorMessage: `Input doesn't match with the variable value type`,
                    excludedStates: [DIRTY, SAVING],
                }),
            );
            setTimeout(
                () => this.variableState && this.saveInput.emit(this.generateState({ error: false, errorMessage: null, excludedStates: [DIRTY, SAVING] })),
                3500,
            );
        }
    }

    handleSaveError(response: VariableValueResponse) {
        let errorMessage;
        if (this.isJSON(response.error)) {
            errorMessage = JSON.parse(response.error).message || 'An error occurred while refreshing the variable';
        } else {
            errorMessage = response.error || 'An error occurred while refreshing the variable';
        }
        const sanitizedMessage = `"${errorMessage.replace(/\\"/g, '"')}"`;
        console.error('Unable to update variable value', response);

        this.saveInput.emit(
            this.generateState({
                stateValue: null,
                error: true,
                errorMessage: sanitizedMessage.slice(1, -1),
                excludedStates: [DIRTY, SAVING],
            }),
        );
        setTimeout(
            () => this.variableState && this.saveInput.emit(this.generateState({ error: false, errorMessage: null, excludedStates: [DIRTY, SAVING] })),
            10000,
        );
    }

    inputFocusLost() {
        const variableValue = getVariableValue(this.variable);
        const dirty = variableValue !== null && this.variableState.value !== null && variableValue !== this.variableState.value;
        this.focusLost.emit(
            this.generateState({
                value: !dirty ? null : this.variableState.value,
                excludedStates: [EDITING],
                includedStates: dirty ? [DIRTY] : undefined,
            }),
        );
    }

    refreshInputValue() {
        this.refreshValue.emit(
            this.generateState({
                includedStates: [REFRESHING],
            }),
        );
        // tslint:disable-next-line: max-line-length
        this.variableService
            .readVariable(this.fqn.toUpperCase())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: VariableValueResponse) => {
                    if (FAILED === response.status) {
                        this.handleRefreshError(response);
                    } else {
                        const refreshedValue = response.response.variableValue;
                        this.refreshValue.emit(
                            this.generateState({
                                newValue: refreshedValue,
                                stateValue: null,
                                excludedStates: [DIRTY],
                                includedStates: [REFRESHED, REFRESHING],
                            }),
                        );
                        setTimeout(
                            () =>
                                this.variableState &&
                                this.refreshValue.emit(
                                    this.generateState({
                                        newValue: refreshedValue,
                                        stateValue: null,
                                        excludedStates: [REFRESHED, REFRESHING],
                                    }),
                                ),
                            25000,
                        );
                    }
                },
                error: (error) => this.handleRefreshError(error),
            });
    }

    handleRefreshError(response: VariableValueResponse) {
        let errorMessage;
        if (this.isJSON(response.error)) {
            errorMessage = JSON.parse(response.error).message || 'An error occurred while refreshing the variable';
        } else {
            errorMessage = response.error || 'An error occurred while refreshing the variable';
        }
        const errorParsed = errorMessage;
        const sanitizedMessage = `"${errorMessage.replace(/\\"/g, '"')}"`;
        console.error('Unable to refresh variable', response);
        this.refreshValue.emit(
            this.generateState({
                stateValue: errorParsed,
                error: true,
                errorMessage: sanitizedMessage.slice(1, -1),
                excludedStates: [DIRTY, SAVING, REFRESHING],
            }),
        );
        setTimeout(
            () =>
                this.variableState &&
                this.refreshValue.emit(
                    this.generateState({
                        stateValue: null,
                        error: false,
                        errorMessage: null,
                        excludedStates: [REFRESHED, REFRESHING],
                    }),
                ),
            10000,
        );
    }

    isJSON(text: string) {
        try {
            const obj = JSON.parse(text);
            if (obj && typeof obj === 'object') {
                return true;
            }
        } catch (error) { }
        return false;
    }

    resetVariable(resetValue: string) {
        if (this.lastResetValueSend === resetValue) {
            return;
        }
        this.isResetting = true;

        const variableFqnToOpc = this.fqnToOpcFormat(this.fqn);
        const variableName = getVariableName(this.variable);
        //const resetValue = '1';
        const variableValue = new VariableValueBody(variableName, resetValue);
        this.saveInput.emit(
            this.generateState({
                excludedStates: [EDITING],
                includedStates: [SAVING],
            }),
        );

        const variableValueType: VariableValueType = VariableValueType[this.variableValueType];
        if (this.isValidValue(variableValueType, resetValue)) {
            this.variableService
                .writeVariable(variableFqnToOpc, variableValue)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (result: VariableValueResponse) => {
                        if (FAILED === result.status) {
                            this.handleSaveError(result);
                            this.isResetting = false;
                        } else {
                            const currentVariableValue = result.response.currentVariableValue;

                            this.saveInput.emit(
                                this.generateState({
                                    newValue: currentVariableValue,
                                    stateValue: null,
                                    error: false,
                                    excludedStates: [DIRTY, SAVING],
                                    includedStates: [UPDATED],
                                }),
                            );

                            this.lastResetValueSend = resetValue;
                            if (resetValue === '1') {
                                setTimeout(() => this.resetVariable('0'), 1000);
                            } else {
                                this.isResetting = false;
                            }
                        }
                    },
                    error: (error) => {
                        this.isResetting = false;

                        this.handleSaveError({
                            error,
                            status: '',
                            response: undefined,
                        });
                    },
                });
        } else {
            this.isResetting = false;
            this.saveInput.emit(
                this.generateState({
                    stateValue: null,
                    error: true,
                    errorMessage: `Input doesn't match with the variable value type`,
                    excludedStates: [DIRTY, SAVING],
                }),
            );
            setTimeout(
                () => this.variableState && this.saveInput.emit(this.generateState({ error: false, errorMessage: null, excludedStates: [DIRTY, SAVING] })),
                3500,
            );
        }
    }

    /* On/Off variable methods */
    switchValue(e) {
        const variableFqnToOpc = this.fqnToOpcFormat(this.fqn);
        const variableName = getVariableName(this.variable);
        const newValue = isActiveVariable(this.variableValue) ? '0' : '1';
        this.variableValue = newValue;
        const variableValue = new VariableValueBody(variableName, newValue);

        // this.saveInput.emit(
        //     this.generateState({
        //         excludedStates: [EDITING],
        //         includedStates: [SAVING],
        //     })
        // );

        const variableValueType: VariableValueType = VariableValueType[this.variableValueType];
        if (this.isValidValue(variableValueType, newValue)) {
            this.variableService
                .writeVariable(variableFqnToOpc, variableValue)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (result: VariableValueResponse) => {
                        if (FAILED === result.status) {
                            const checkbox = document.getElementById(this.fqn.toUpperCase()) as HTMLInputElement;
                            checkbox.checked = isActiveVariable(this.variableValue);
                            this.handleSaveError(result);
                        } else {
                            const currentVariableValue = result.response.currentVariableValue;
                            this.saveInput.emit(
                                this.generateState({
                                    newValue: currentVariableValue,
                                    stateValue: null,
                                    error: false,
                                    excludedStates: [DIRTY, SAVING],
                                    includedStates: [UPDATED],
                                }),
                            );
                        }
                    },
                    error: (error: string) => {
                        const checkbox = document.getElementById(this.fqn.toUpperCase()) as HTMLInputElement;
                        checkbox.checked = isActiveVariable(this.variableValue);
                        this.handleSaveError({
                            error,
                            status: '',
                            response: undefined,
                        });
                    },
                });
        } else {
            this.saveInput.emit(
                this.generateState({
                    stateValue: null,
                    error: true,
                    errorMessage: `Input doesn't match with the variable value type`,
                    excludedStates: [DIRTY, SAVING],
                }),
            );
            setTimeout(
                () => this.variableState && this.saveInput.emit(this.generateState({ error: false, errorMessage: null, excludedStates: [DIRTY, SAVING] })),
                3500,
            );
        }
    }

    generateState(stateOptions: any) {
        const { event, newValue, stateValue, error, errorMessage, excludedStates, includedStates } = stateOptions;
        return {
            ...(event ? { event } : {}),
            ...(newValue ? { newValue } : {}),
            variable: this.variable,
            state: {
                ...this.variableState,
                ...(stateValue !== undefined ? { value: stateValue } : {}),
                ...([false, true].includes(error) ? { error, errorMessage } : {}),
                ...(excludedStates || includedStates
                    ? {
                        inputState: [
                            ...new Set([
                                // ...new Set( // Avoid duplicates
                                ...(this.variableState?.inputState || []).filter((state) => !(excludedStates || []).includes(state)),
                                ...(includedStates ? includedStates : []),
                                // )
                            ]),
                        ],
                    }
                    : {}),
            },
        };
    }

    fqnToOpcFormat(fqn): string {
        const [warehouse, floor, area, zone, line, equipmentType, equipment, variableType, variable] = fqn.toUpperCase().replaceAll('-', '.').split('.')
        return `${warehouse}-${floor}-${area}-${zone}-${line}-${equipmentType}-${equipment}-${variableType.toUpperCase().replace('ALARM', 'FAILURE')}-${variable}`.toUpperCase();
    }
}
