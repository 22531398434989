import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'archiveType',
    standalone: true,
})
export class ArchiveTypePipe implements PipeTransform {

    private readonly sanitizer = inject(DomSanitizer);

    constructor() { }

    transform(report: { reportFileName: string }): any {
        const { reportFileName } = report;
        const response = reportFileName.startsWith('AlertsHistoryReport_') ? `Alerts` : `Variables`;
        return this.sanitizer.bypassSecurityTrustHtml(response);
    }
}
