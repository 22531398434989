"use strict";

var indexOf = require("es5-ext/array/#/e-index-of");
module.exports = function () {
  var lastId = 0,
    argsMap = [],
    cache = [];
  return {
    get: function (args) {
      var index = indexOf.call(argsMap, args[0]);
      return index === -1 ? null : cache[index];
    },
    set: function (args) {
      argsMap.push(args[0]);
      cache.push(++lastId);
      return lastId;
    },
    delete: function (id) {
      var index = indexOf.call(cache, id);
      if (index !== -1) {
        argsMap.splice(index, 1);
        cache.splice(index, 1);
      }
    },
    clear: function () {
      argsMap = [];
      cache = [];
    }
  };
};