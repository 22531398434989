// import { Variable } from '@variables/shared/variable';
import { VARIABLE_EMPTY_REQUEST_ID, VariableActiveStatus, VariableActiveStatusGroup, VariableNotification } from '../events/variable-status';

// const initialVariablesStatusMapper = (variableStatusNotification: Variable): VariableNotification => {
//     // console.log('initial', variableStatusNotification);
//     const result = {
//         requestId: VARIABLE_EMPTY_REQUEST_ID,
//         floorId: variableStatusNotification.floor,
//         areaId: variableStatusNotification.area,
//         zoneId: variableStatusNotification.zone,
//         lineId: variableStatusNotification.line,
//         equipmentId: variableStatusNotification.equipmentId,
//         equipmentType: variableStatusNotification.equipmentType,
//         fqn: variableStatusNotification.fqn,
//         source: variableStatusNotification.source,
//         timestamp: variableStatusNotification.variableSourceTimestamp?.toString(),
//         sourceTimeStamp: variableStatusNotification.variableSourceTimestamp?.toString(),
//         serverTimeStamp: variableStatusNotification.serverTimeStamp?.toString(),
//         variableName: variableStatusNotification.variableName,
//         variableType: variableStatusNotification.variableType.replace(/FAILURE/gi, 'ALARM'),
//         variableValue: variableStatusNotification.variableValue,
//         variableValueType: variableStatusNotification.variableValueType,
//         isOpcError: variableStatusNotification.isOpcError,
//         opcErrorType: variableStatusNotification.opcErrorType,
//     };
//     console.log(variableStatusNotification, result);
//     return result;
// };

// const initialVariablesStatusMapper = (variableStatusNotification: VariableActiveStatus): VariableNotification => {
const initialVariablesStatusMapper = (variableActiveStatusGroup: VariableActiveStatus[]): VariableNotification[] => {
    return variableActiveStatusGroup.map((variableStatusNotification) => ({
        requestId: VARIABLE_EMPTY_REQUEST_ID,
        timestamp: variableStatusNotification.timestamp,
        sourceTimeStamp: variableStatusNotification.sourceTimeStamp,
        serverTimeStamp: variableStatusNotification.serverTimeStamp,
        variableName: variableStatusNotification.variableName,
        variableType: variableStatusNotification.variableType.replace(/FAILURE/gi, 'ALARM'),
        variableValue: variableStatusNotification.value,
        variableValueType: variableStatusNotification.valueType,
        floorId: variableStatusNotification.floorid,
        areaId: variableStatusNotification.areaid,
        zoneId: variableStatusNotification.zoneid,
        lineId: variableStatusNotification.lineid,
        equipmentId: variableStatusNotification.equipmentId,
        equipmentType: variableStatusNotification.equipmentType,
        fqn: variableStatusNotification.fqn,
        source: variableStatusNotification.source,
        isOpcError: variableStatusNotification.isOpcError,
        opcErrorType: variableStatusNotification.opcErrorType,
    }));
};

const variablesStatusMapper = (variableActiveStatusGroup: VariableActiveStatusGroup): VariableNotification[] => {
    return variableActiveStatusGroup.notifications.map((variableStatusNotification) => ({
        requestId: variableStatusNotification.requestId || variableActiveStatusGroup['requestId'],
        floorId: variableStatusNotification.floorid,
        areaId: variableStatusNotification.areaid,
        zoneId: variableStatusNotification.zoneid,
        lineId: variableStatusNotification.lineid,
        equipmentId: variableStatusNotification.equipmentId,
        equipmentType: variableStatusNotification.equipmentType,
        fqn: variableStatusNotification.fqn,
        source: variableStatusNotification.source,
        timestamp: variableStatusNotification.timestamp,
        sourceTimeStamp: variableStatusNotification.sourceTimeStamp,
        serverTimeStamp: variableStatusNotification.serverTimeStamp,
        variableName: variableStatusNotification.variableName,
        variableType: variableStatusNotification.variableType.replace(/FAILURE/gi, 'ALARM'),
        variableValue: variableStatusNotification.value,
        variableValueType: variableStatusNotification.valueType,
        isOpcError: variableStatusNotification.isOpcError,
        opcErrorType: variableStatusNotification.opcErrorType,
    }));
};

export {
    variablesStatusMapper,
    initialVariablesStatusMapper
};
