<offcanvas
    #offcanvas
    [modalId]="modalId"
    title="Navigation & Equipment data"
    position="end"
    [transparent]="isLineView"
    [width]="isMobile ? '100%' : isLineView ? '50%' : '60%'"
>
    <div class="col-12 col-lg-4 search-panel">
        <div class="row">
            <div class="col-12">
                <p>Navigate to...</p>
            </div>
            <app-select-virtual
                key="lines-search"
                [item$]="lines"
                label="Equipment-line"
                columns="12"
                placeholder="Please select an equipment-line"
                searchable="true"
                (change)="onLineSelection($event)"
            ></app-select-virtual>
            <app-input-2
                #equipmentsSearch2
                key="equipments-search"
                label="Equipment"
                columns="12"
                [autocomplete]="equipments"
                (selectAutocomplete)="navigateToEquipment($event)"
                extraClasses="mb-3"
            ></app-input-2>
            <p *ngIf="showWarningMessage" class="col-12 col-md-6 col-lg-12 warningMessage">Please, enter an equipment name to search for...</p>
            <hr />
            <div class="col-12 my-3">
                <p>Type the line’s code that you’re looking for</p>
            </div>
            <app-select
                #linesSelector
                [item$]="lines"
                key="data-lines-search"
                label="Equipment-line"
                columns="12"
                placeholder="Please select an equipment-line"
                searchable="true"
                [selectedValue]="selectedValue"
            ></app-select>
            <app-select
                #equipmentsSelector
                [item$]="lineEquipments"
                key="data-equipments-search"
                label="Equipment"
                columns="12"
                placeholder="Please select an equipment name"
                extraClasses="mb-3"
            ></app-select>
            <hr />
            <div class="col-12 mt-3">
                <p>Or type an equipment’s code and then press Enter</p>
            </div>
            <app-input
                #equipmentsSearch
                key="equipment-search-modal"
                label="Equipment"
                columns="12"
                [autocomplete]="equipments"
                (selectAutocomplete)="getVariablesFromEquipment($event)"
            ></app-input>
        </div>
    </div>
    <div class="col-12 col-lg-8">
        <app-loading-spinner *ngIf="isLoading" [extraClasses]="'row mt-5 mb-5'"></app-loading-spinner>
        <div class="row">
            <div class="col-md-12" [hidden]="isLoading">
                <div class="row">
                    <h6 class="mt-3 col-md-6">
                        <strong class="force-one-line">{{ currentLine }}</strong>
                    </h6>
                    <!-- <button
                        class="btn-auwa col-md-5 ms-auto me-1 mt-2"
                        style="min-width: fit-content; position: relative"
                        [disabled]="isWaitingForWorkOrderResponse"
                        (click)="onCreateWorkOrder(currentLine)"
                    >
                        <i *ngIf="!isWaitingForWorkOrderResponse" class="fas fa-tools"></i>{{ isWaitingForWorkOrderResponse ? '' : 'New IMMS WO'
                        }}<app-loading-spinner *ngIf="isWaitingForWorkOrderResponse"></app-loading-spinner>
                    </button> -->
                </div>
            </div>
            <div class="col-md text-end align-middle mt-3 equipment-detail"></div>
        </div>
        <ul class="nav nav-tabs secondary-tab mb-0" id="lines-data-detail" role="tablist" [hidden]="isLoading || !currentLine">
            <li class="nav-item">
                <a
                    class="nav-link equipment-tab active"
                    id="variables-tab"
                    data-bs-toggle="tab"
                    href="#variables"
                    role="tab"
                    aria-controls="variables"
                    aria-selected="true"
                    (click)="showVariables()"
                    >Variables</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link equipment-tab"
                    id="analytics-tab"
                    data-bs-toggle="tab"
                    href="#analytics"
                    role="tab"
                    aria-controls="analytics"
                    aria-selected="false"
                    (click)="showAnalytics()"
                    >Analytics</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link equipment-tab"
                    id="equipment-view-tab"
                    data-bs-toggle="tab"
                    href="#equipmentview"
                    role="tab"
                    aria-controls="equipmentview"
                    aria-selected="false"
                    (click)="showEquipmentView()"
                    >Equipment View</a
                >
            </li>
        </ul>
        <div class="tab-content" id="lineDetail" [hidden]="isLoading || !currentLine">
            <div class="tab-pane fade show active" id="variables" role="tabpanel" aria-labelledby="variables-tab">
                <div class="row">
                    <div class="col-12 mt-2 mb-2">
                        <button
                            class="btn btn-secondary float-end"
                            style="margin-top: -11px; margin-left: 2px"
                            (click)="forceRefreshVariables()"
                            title="Refresh all variables from OPC"
                            [disabled]="refreshingAll"
                        >
                            <i class="fa fa-sync-alt refresh" [ngClass]="refreshingAll ? 'spinner' : ''"></i>
                        </button>
                        <button
                            class="btn btn-secondary float-end"
                            style="margin-top: -11px; margin-left: 2px"
                            (click)="reloadData()"
                            title="Reload data from server"
                        >
                            <i class="fa fa-retweet"></i>
                        </button>
                        <button
                            class="btn float-end"
                            [ngClass]="onlyShowActive ? 'btn-action' : 'btn-secondary'"
                            style="margin-top: -11px; margin-left: 2px"
                            (click)="toggleViewActive()"
                            [title]="onlyShowActive ? 'Show all' : 'Show actives'"
                        >
                            <i class="fa" [ngClass]="onlyShowActive ? 'fa-eye-slash' : 'fa-eye'"></i>
                        </button>
                        <button
                            class="btn float-end"
                            [ngClass]="sortProperty === NAME ? 'btn-action' : 'btn-secondary'"
                            style="margin-top: -11px; margin-left: 2px"
                            (click)="applySort(NAME)"
                            [title]="'Sort by Name'"
                        >
                            <i class="fa me-1" [ngClass]="sortProperty !== NAME ? 'fa-sort-down' : sortOrder === DESC ? 'fa-sort-up' : 'fa-sort-down'"></i>Name
                        </button>
                        <button
                            class="btn float-end"
                            [ngClass]="sortProperty === TYPE ? 'btn-action' : 'btn-secondary'"
                            style="margin-top: -11px; margin-left: 2px"
                            (click)="applySort(TYPE)"
                            [title]="'Sort by Type'"
                        >
                            <i class="fa me-1" [ngClass]="sortProperty !== TYPE ? 'fa-sort-down' : sortOrder === DESC ? 'fa-sort-up' : 'fa-sort-down'"></i>Type
                        </button>
                        <button
                            class="btn float-end"
                            [ngClass]="sortProperty === ACTIVE ? 'btn-action' : 'btn-secondary'"
                            style="margin-top: -11px; margin-left: 2px"
                            (click)="applySort(ACTIVE)"
                            [title]="'Sort by Active'"
                        >
                            <i class="fa me-1" [ngClass]="sortProperty !== ACTIVE ? 'fa-sort-down' : sortOrder === DESC ? 'fa-sort-up' : 'fa-sort-down'"></i
                            >Active
                        </button>
                    </div>
                    <app-input
                        #variableSearch
                        label="Search"
                        key="line-data-modal-search"
                        columns="6"
                        placeholder="Type to search (eg: nok, rfid...)"
                    ></app-input>
                    <app-select
                        #variableTypeSelector
                        [item$]="variableTypes"
                        key="data-variable-type"
                        label="Variable Type"
                        columns="6"
                        placeholder="Please select a Variable Type"
                    ></app-select>
                    <ng-container *ngFor="let variable of equipmentVariables">
                        <div
                            class="col-12 col-sm-6 col-lg-12"
                            *ngIf="
                                (!onlyShowActive || isActiveVariable(variable.value)) &&
                                (!variableNameFilter || variable.name.toLowerCase().includes(variableNameFilter.toLowerCase())) &&
                                (selectedType === '' || variable.type.toLowerCase() === selectedType.toLowerCase())
                            "
                        >
                            <ng-container *ngIf="(fqnPrefixForOpc + '.' + variable.type + '.' + variable.name).toUpperCase() as fqn">
                                <app-line-parameter
                                    [variable]="variable"
                                    [fqn]="fqn"
                                    [isRefreshing]="refreshingVariables.includes(fqn)"
                                    [hasRefreshError]="refreshErrors.includes(fqn)"
                                ></app-line-parameter>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div class="col-12" *ngIf="equipmentVariables.length === 0">
                        <p>We don't have variables for this equipment...</p>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"></div>
            <div class="tab-pane fade" id="analytics" role="tabpanel" aria-labelledby="analytics-tab">
                <app-loading-spinner *ngIf="isLoadingAnalytics" [extraClasses]="'row mt-5 mb-5'"></app-loading-spinner>
                <div class="row mt-5 tab-pane" id="analytics" style="align-items: center" [hidden]="isLoadingAnalytics">
                    <div class="col-12">
                        <shared-analytics-panel [kpis]="kpis"></shared-analytics-panel>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="equipmentview" role="tabpanel" aria-labelledby="equipment-view-tab">
                <app-loading-spinner *ngIf="isLoadingEquipmentView" [extraClasses]="'row mt-2 mb-5'"></app-loading-spinner>
                <div class="row mt-1 mb-3 tab-pane" [hidden]="isLoadingEquipmentView">
                    <div class="col-12 col-md-8 offset-md-2 text-center mt-3" [hidden]="backgroundImage !== ''">
                        <p>We don't have view for this equipment...</p>
                    </div>
                    <div class="col-12 col-md-10 offset-md-1" [hidden]="backgroundImage === ''">
                        <svg #svg [attr.viewBox]="setViewBox" class="border rounded metadataLastLevel">
                            <svg:image *ngIf="backgroundImage !== ''" [attr.href]="backgroundImage" height="100%" width="100%" x="0" y="0"></svg:image>
                            <svg:g class="variablesGroup">
                                <ng-container *ngFor="let block of metadata?.variables?.blocks">
                                    <svg:g
                                        class="shape block"
                                        [attr.transform]="block?.transform"
                                        [attr.variable]="block?.variable"
                                        [attr.description]="block?.description"
                                    >
                                        <ng-container *ngFor="let item of block?.shapes">
                                            <svg:path
                                                *ngIf="item && item.data !== 'null'"
                                                [attr.data-element]="'shape'"
                                                [attr.data-key]="item.variable"
                                                [ngClass]="{ withVariable: item.variable, highlightPath: item.highlightPath }"
                                                [attr.d]="item.data"
                                                [attr.variable]="item.variable"
                                                [attr.description]="item.description"
                                            >
                                                <title>{{ item.variable }} - {{ item.description }}</title>
                                            </svg:path>
                                        </ng-container>
                                        <ng-container *ngFor="let item of block?.texts">
                                            <svg:text
                                                class="shape"
                                                [attr.data-element]="'shape'"
                                                [attr.font-size]="item.size || '16'"
                                                [attr.x]="item.x"
                                                [attr.y]="item.y"
                                                [attr.data-key]="item.variable ? 'text' + item.variable : ''"
                                                [attr.variable]="item.variable"
                                                [attr.description]="item.description"
                                            >
                                                <tspan [attr.text-anchor]="item.variable ? 'middle' : 'left'" dy="20">{{ item.text }}</tspan>
                                                <title>{{ item.variable }}</title>
                                            </svg:text>
                                        </ng-container>
                                    </svg:g>
                                </ng-container>
                                <ng-container *ngFor="let item of metadata?.variables?.shapes">
                                    <svg:path
                                        [attr.data-key]="item.variable"
                                        [attr.class]="item.class"
                                        [attr.data-element]="'shape'"
                                        [attr.d]="item.data"
                                        [attr.variable]="item.variable"
                                        [attr.description]="item.description"
                                        *ngIf="item.data !== 'null'"
                                    >
                                        <title>{{ item.variable }}</title>
                                    </svg:path>
                                </ng-container>
                                <ng-container *ngFor="let item of metadata?.variables?.texts">
                                    <svg:text
                                        class="shape"
                                        [attr.data-element]="'shape'"
                                        [attr.font-size]="item.size || '16'"
                                        [attr.x]="item.x"
                                        [attr.y]="item.y"
                                        [attr.variable]="item.variable"
                                        [attr.description]="item.description"
                                    >
                                        <!--[attr.data-key]="item.variable ? 'text' + item.variable : ''"-->
                                        <tspan dy="20">{{ item.text }}</tspan>
                                        <title>{{ item.variable }}</title>
                                    </svg:text>
                                </ng-container>
                            </svg:g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</offcanvas>
<app-modal-confirmation #workOrderConfirmationModal key="workOrderConfirmationModal" (responseChanged)="confirmationEmited($event)">
    <span >Do you want to issue a work order for the equipment with ID <strong>{{ equipmentIds[0] }}</strong> ?</span>
</app-modal-confirmation>

<div aria-live="polite" class="failure-origin-toast" aria-atomic="true" style="position: fixed; width: 350px; top: 55px; right: 14px; z-index: 1500">
    <ng-container *ngFor="let immsWorkOrderToast of immsWorkOrderToasts">
        <div *ngIf="immsWorkOrderToast.uuid" class="toast fade hide mb-3" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header toast-header-success">
                <i class="pe-2 fas fa-check"></i>
                <strong class="me-auto">{{ immsWorkOrderToast.title }}</strong>
                <small class="text-muted">{{ immsWorkOrderToast.code }}</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body" [innerHTML]="immsWorkOrderToast.msg"></div>
        </div>
    </ng-container>
</div>
