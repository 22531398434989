import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';
import dayjs from 'dayjs';
import { ImageSecureService } from '../services/image-secure.service';

@Pipe({
    name: 'downloadResourceIcon',
    standalone: true,
})
export class DownloadResourceIconPipe implements PipeTransform {

    private readonly sanitizer = inject(DomSanitizer);
    private readonly appLoadService = inject(AppLoadService);
    private readonly imageSecureService = inject(ImageSecureService);

    constructor() { }

    async transform(report: { reportFileName: string; reportDownloadLink: string; reportGenerationTimeStamp: string; warehouseId: string }): Promise<any> {
        const { reportFileName, reportDownloadLink, reportGenerationTimeStamp, warehouseId } = report;
        const lessThanOneHourAgo = dayjs(reportGenerationTimeStamp).isAfter(dayjs().subtract(5, 'minutes'));
        let response = `<i title="An error ocurred" class="fa fa-exclamation-circle" aria-hidden="true"></i>`; // default response
        if (!!reportDownloadLink) {
            const warehouse = await this.appLoadService.getCurrentWarehouse.pipe(take(1)).toPromise();
            const donwloadLink = `${warehouse.hostName}/api/archives/${warehouseId}/reportList/${reportFileName}/download`;
            const securedLink = await this.imageSecureService.setImageAsSecure(donwloadLink).pipe(take(1)).toPromise();
            response = `<a href="${securedLink?.['changingThisBreaksApplicationSecurity']}" target="_BLANK"><i class="fa fa-download" aria-hidden="true"></i></a>`;
        } else if (lessThanOneHourAgo) {
            response = `<i title="Generating report" class="fa fa-spinner spinner" aria-hidden="true"></i>`;
        }
        return this.sanitizer.bypassSecurityTrustHtml(response);
    }
}
