import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FloorsService } from '@floors/shared/floors.service';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { AppLoadService } from '@app/app-load.service';

export const FloorsResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot): Observable<any> => {
    const floorsService = inject(FloorsService);
    const equipmentsService = inject(EquipmentsService);
    const appLoadService = inject(AppLoadService);

    return forkJoin([
        floorsService.getFloor(route.params.floorId).pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el provider al recuperar el mapa de la planta');
                return of(null);
            }),
        ),
        equipmentsService.getEquipmentTypes().pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar los tipos de equipos');
                return of(null);
            }),
        ),
        appLoadService.getCurrentWarehouse.pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar el almacen actual', err);
                return of(null);
            }),
        ),
        appLoadService.getCurrentConfiguration.pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar la configuracion', err);
                return of(null);
            }),
        ),
    ]).pipe(
        map((x) => {
            return {
                map: x[0],
                equipmentsTypes: x[1],
                warehouse: x[2],
                configuration: x[3],
            };
        }),
    );

}