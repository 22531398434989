import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AppLoadService } from '@app/app-load.service';

@Injectable({ providedIn: 'root' })
export class ImmsService {
    baseUrl: string;
    warehouse: string;

    constructor(
        private http: HttpClient,
        private appLoadService: AppLoadService,
    ) {}

    createWorkOrder(equipmentId: string): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                const baseUrl = `${res.hostName}/api/work-orders`.toLowerCase();
                const warehouse = res.warehouse;
                return this.http.post<any>(`${baseUrl}/${warehouse}`, { equipmentId });
            }),  
        );
    }
}