import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppLoadService } from '@app/app-load.service';
import { SignalREvents } from '@app/notifications/shared/clients/signalr-status.client';
import { VariableActiveStatus, VariableNotification } from '@app/notifications/shared/events/variable-status';
import { mapNotification } from '@app/notifications/shared/mappers/notification.mapper';
import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Variable, VariablesResponse, VariablesUrlFilter, VariableValueBody, VariableValueResponse } from './variable';
import { VariablesHistory } from './variables';

@Injectable({ providedIn: 'root' })
export class VariablesService {
    baseUrl: string;
    warehouse: string;

    private readonly http = inject(HttpClient);
    private readonly appLoadService = inject(AppLoadService);

    constructor() { }

    getVariablesHistory({
        page = '0',
        pageSize = '10',
        from = '',
        to = '',
        floorid = '',
        areaid = '',
        zoneid = '',
        lineid = '',
        equipmentId = '',
        equipmentType = '',
        fqnPattern = '',
        variable = '',
        variableType = '',
        condition = null,
        orderColumn = 'floor',
        orderType = 'desc',
    }): Observable<VariablesHistory> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                let params = new HttpParams()
                    .set('pagesize', pageSize)
                    .set('pagenumber', `${page}`)
                    .set('orderColumn', orderColumn)
                    .set('orderType', orderType);
                if (from) {
                    const utcFrom = dayjs(from.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    params = params.set('from', utcFrom);
                }
                if (to) {
                    const utcTo = dayjs(to.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    params = params.set('to', utcTo);
                }
                if (lineid) {
                    params = params.set('lineid', lineid);
                }
                if (equipmentId) {
                    params = params.set('equipmentId', equipmentId);
                }
                if (equipmentType) {
                    params = params.set('equipmentType', equipmentType);
                }
                if (variable) {
                    params = params.set('variableName', variable);
                }
                if (variableType) {
                    params = params.set('variableType', variableType.toLowerCase());
                }
                if (fqnPattern) {
                    params = params.set('fqnPattern', fqnPattern);
                }
                const endpoint = zoneid  ? `variables/${this.warehouse}/history/floors/${floorid}/areas/${areaid}/zones/${zoneid}` :
                                 areaid  ? `variables/${this.warehouse}/history/floors/${floorid}/areas/${areaid}` :
                                 floorid ? `variables/${this.warehouse}/history/floors/${floorid}`
                                         : `variables/${this.warehouse}/history`;
                return this.http.post<VariablesHistory>(`${this.baseUrl}/${endpoint}`, { condition }, { params });
            }),
            take(1),
            //shareReplay(),
        );
    }

    readVariable(fqn: string): Observable<VariableValueResponse> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/v2`.toLowerCase();
                this.warehouse = res.warehouse;
                const params = new HttpParams().set('fqdn', fqn);
                const endpoint = `variables/${this.warehouse}`;
                return this.http.get<VariableValueResponse>(`${this.baseUrl}/${endpoint}`, { params });
            }),
            take(1),
            //shareReplay(),
        );
    }

    writeVariable(fqn: string, variableValue: VariableValueBody): Observable<VariableValueResponse> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/v2`.toLowerCase();
                this.warehouse = res.warehouse;
                const params = new HttpParams().set('fqdn', fqn);
                const endpoint = `variables/${this.warehouse}`;
                return this.http.put<VariableValueResponse>(`${this.baseUrl}/${endpoint}`, variableValue, { params });
            }),
            take(1),
            //shareReplay(),
        );
    }

    getVariables(filter: VariablesUrlFilter): Observable<VariableNotification[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;

                //const urlFilter = filter?.toString();
                //const endpoint = `variable/${this.warehouse}${urlFilter ? `?${urlFilter}` : ''}`;
                const endpoint = `variables/${this.warehouse}/active`;
                return this.http.post<VariablesResponse>(`${this.baseUrl}/${endpoint}`, JSON.stringify(filter));
            }),
            map((notifications) => {
                const mappedVariables: VariableNotification[] = notifications.notifications.map((notification) =>
                    mapNotification(SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED, notification),
                );
                return mappedVariables;
            }),
            take(1),
            //shareReplay(),
        );
    }

    getInitialLoadVariables(filter: VariablesUrlFilter): Observable<Variable[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api/V2`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `variables/${this.warehouse}/active`;
                return this.http.post<VariablesResponse>(`${this.baseUrl}/${endpoint}`, JSON.stringify(filter));
            }),
            take(1),
            map(notificationGroup => notificationGroup.notifications),
            catchError((err) => {
                console.error(`Ha ocurrido un problema en el service al recuperar el estado de las variables -> ${err}`);
                return of();
            }),
        );
    }

    getVariablesFromLine(floorId: string, areaId?: string, zoneId?: string, lineId?: string): Observable<VariableNotification[]> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                /* eslint-disable prettier/prettier */
                const endpoint = !!lineId ? `V2/variables/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}` :
                    !!zoneId ? `V2/variables/${this.warehouse}/floors/${floorId}/areas/${areaId}/zones/${zoneId}` :
                        !!areaId ? `V2/variables/${this.warehouse}/floors/${floorId}/areas/${areaId}`
                            : `V2/variables/${this.warehouse}/floors/${floorId}`;
                /* eslint-enable prettier/prettier */
                return this.http.get<VariableActiveStatus[]>(`${this.baseUrl}/${endpoint}`);
            }),
            map((notifications) => {
                const mappedVariables: VariableNotification[] = notifications.map((notification) =>
                    mapNotification(SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED, notification),
                );
                return mappedVariables;
            }),
            take(1),
            //shareReplay(),
        );
    }


    setBackgroundRowColorByZone(zoneClassKey: string, zonesWithColors, originalClassName: string = 'active') {
        document.querySelectorAll<HTMLElement>(`.${zoneClassKey}`).forEach((element) => {
            element.removeAttribute('style');
        });
        const zoneFounded = zonesWithColors.find((zone) => zoneClassKey.endsWith(zone.zone))
        if (zoneFounded) {
            document.querySelectorAll<HTMLElement>(`.${originalClassName}-${zoneFounded.zone}`).forEach((row) => {
                row.style.backgroundColor = row.classList.contains('active') ? '' : zoneFounded.color;
            });
        }
    }
}
