import { inject, } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { HomeService } from '@app/map/home/shared/home.service';
import { EquipmentsService } from '@app/map/equipments/equipments.service';
import { AppLoadService } from '@app/app-load.service';
import { ResolveFn } from '@angular/router';

export const CustomsResolver: ResolveFn<any> = (): Observable<any> => {
    const homeService = inject(HomeService);
    const equipmentsService = inject(EquipmentsService);
    const appLoadService = inject(AppLoadService);

    return forkJoin([
        homeService.getStructure().pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar la estructura');
                return of(null);
            }),
        ),
        equipmentsService.getEquipmentTypes().pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar los tipos de equipos');
                return of(null);
            }),
        ),
        appLoadService.getCurrentWarehouse.pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar el almacen actual', err);
                return of(null);
            }),
        ),
        appLoadService.getCurrentConfiguration.pipe(
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema al recuperar la configuracion', err);
                return of(null);
            }),
        ),
    ]).pipe(
        map((x) => {
            return {
                structure: x[0],
                equipmentTypes: x[1],
                warehouse: x[2],
                configuration: x[3],
            };
        }),
    );
}