/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { EquipmentVariable } from '@app/map/equipments/equipment-variable';
import { VariableFilter, VariableFilters, VariableNotification, WILD_CARD_CHARACTER } from '@app/notifications/shared/events/variable-status';
import memoize from 'memoizee';

export class Variable {
    floor: string;
    area: string;
    zone: string;
    line: string;
    equipmentId: string;
    equipmentType: string;
    fqn: string;
    source: string;
    variableName: string;
    variableSourceTimestamp: Date;
    serverTimeStamp: Date;
    variableType: string;
    variableValue: any;
    variableValueType: string;
    isOpcError?: boolean;
    opcErrorType?: string;

    constructor(
        floor: string,
        area: string,
        zone: string,
        line: string,
        equipment: string,
        equipmentType: string,
        fqn: string,
        source: string,
        variableName: string,
        variableSourceTimestamp: Date,
        variableType: string,
        variableValue: boolean,
        variableValueType: string,
        isOpcError: boolean = null,
        opcErrorType: string = null,
    ) {
        this.floor = floor;
        this.area = area;
        this.zone = zone;
        this.line = line;
        this.equipmentId = equipment;
        this.equipmentType = equipmentType;
        this.fqn = fqn;
        this.source = source;
        this.variableName = variableName;
        this.variableSourceTimestamp = variableSourceTimestamp;
        this.variableType = variableType;
        this.variableValue = variableValue;
        this.variableValueType = variableValueType;
        this.isOpcError = isOpcError;
        this.opcErrorType = opcErrorType;
    }
}

export class VariablesResponse {
    numberOfItems?: number; //Can delete?
    pageNumber?: number; //Can delete?
    items?: Array<Variable>; //Can delete?
    notifications?: Array<Variable>; //In new version i think is the only used attr

    constructor(numberOfItems: number, pageNumber: number, items: Array<Variable>) {
        this.numberOfItems = numberOfItems;
        this.pageNumber = pageNumber;
        this.items = items;
    }
}

export class VariableHistory {
    warehouseId: string;
    floorid: string;
    areaid: string;
    zoneid: string;
    lineid: string;
    equipmentId: string;
    equipmentType: string;
    fqn: string;
    source: string;
    sourceTimeStamp: string;
    timestamp: string;
    value: string;
    valueDataType: string;
    variableName: string;
    variableType: string;

    constructor(
        floor: string,
        area: string,
        zone: string,
        line: string,
        equipment: string,
        equipmentType: string,
        fqn: string,
        source: string,
        variableName: string,
        timestamp: string,
        sourceTimeStamp: string,
        variableType: string,
        value: string,
        valueDataType: string,
    ) {
        this.floorid = floor;
        this.areaid = area;
        this.zoneid = zone;
        this.lineid = line;
        this.equipmentId = equipment;
        this.equipmentType = equipmentType;
        this.fqn = fqn;
        this.source = source;
        this.variableName = variableName;
        this.timestamp = timestamp;
        this.sourceTimeStamp = sourceTimeStamp;
        this.variableType = variableType;
        this.value = value;
        this.valueDataType = valueDataType;
    }
}

export class VariableValueBody {
    VariableName: string;
    Value: string;

    constructor(name: string, value: string) {
        this.VariableName = name;
        this.Value = value;
    }
}

export class VariableValueResponse {
    status: string;
    response: any;
    error: string;

    constructor(status: string, response: any, error: string) {
        this.status = status;
        this.response = response;
        this.error = error;
    }
}

export const VARIABLE_PLC_NOT_COMM = 'PLC_NOT_COMM'

export enum VariableType {
    ALARM = 'Alarm',
    COMMAND = 'Command',
    STATUS = 'Status',
    WARNING = 'Warning',
}

export enum VariableDashboardType {
    realTime = 'Real Time',
    historic = 'Historic',
}

export const isActiveVariable = (value) => ![undefined, 'false', '0'].includes(value?.toLowerCase());

export enum VARIABLES_MODE {
    RT = 'RT',
    HISTORY = 'History',
    DASHBOARD = 'Dashboard',
}

export interface VariableColors {
    variable: string; //completeVariableFQN
    dataType?: string;
    settings: VariableColorSettings[];
}
export interface VariableColorSettings {
    id: string;
    enviroment: string;
    //from: string,
    to: string;
    color: string;
    visible: boolean;
}

export class VariablesUrlFilter {
    // Common
    fqn?: string;
    faz?: string;
    floorId?: string;
    areaId?: string;
    zoneId?: string;
    lineId?: string;
    fqnPattern?: string;
    equipmentId?: string;
    equipmentType?: string;
    variableType?: string;
    variableName?: string;
    orderColumn?: string;
    orderType?: string;

    // Only for Active
    Value?: string;
    ValueMin?: string;
    ValueMax?: string;
    operator?: string;

    toString(): string {
        return Object.keys(this)
            .map((key) => {
                if (this[key] !== undefined) {
                    return `${key}=${this[key]}`;
                }
            })
            .join('&');
    }
}

export class VariablesViewState {
    mode?: string;

    // Only for History
    from?: string;
    to?: string;
    page?: string;
    pageSize?: string;

    // Current filters
    f?: string;
}

export interface ZoneColors {
    zone: string;
    color: string;
}
export interface ScannerTrendColors {
    scanner: string;
    firstRange: number;
    secondRange: number;
    firstColorRange: string;
    secondColorRange: string;
    thirdColorRange: string;
    color?: string;
}
export interface ErrorTrendColors {
    fqn: string;
    firstRange: number;
    secondRange: number;
    firstColorRange: string;
    secondColorRange: string;
    thirdColorRange: string;
    color?: string;
}

// export interface BoxDetectedColors {
//     lightMode: string;
//     darkMode: string;
// }

//const { COMMAND, ALARM, WARNING, STATUS } = VariableType;

export const getVariableType = (variable: Variable | VariableHistory | VariableNotification | EquipmentVariable): string =>
    (variable as Variable | VariableHistory).variableType || (variable as EquipmentVariable).type;

export const getVariableValue = (variable: Variable | VariableHistory | VariableNotification | EquipmentVariable): string =>
    (variable as Variable).variableValue || (variable as VariableHistory | EquipmentVariable).value;

export function isRunVariable(variable) {
    const name: string = (variable as Variable | VariableHistory | VariableNotification).variableName ?? (variable as EquipmentVariable).name;
    return name.toLowerCase() === 'run' && compareType(variable, VariableType.STATUS);
}

export function compareType(variable: Variable | VariableHistory | VariableNotification | EquipmentVariable, type: VariableType) {
    const variableType = getVariableType(variable);
    return variableType.toLowerCase() === type.toLowerCase();
}

export function setFqnPreferences(floor, identifierFloor, area, identifierArea, zone, identifierZone, line, identifierLine): string {
    if (!identifierFloor) {
        floor = floor.replace('F', '');
    }
    if (!identifierArea) {
        area = area.replace('A', '');
    }
    if (!identifierZone) {
        zone = zone.replace('Z', '');
    }
    if (!identifierLine) {
        line = line.replace('EQL', '');
    }
    return `${floor}-${area}-${zone}-${line}`;
}

export function setIdentifiers(floorId, areaId, zoneId, lineId): string[] {
    if (!floorId.startsWith('F')) floorId = `F${floorId}`;
    if (!areaId.startsWith('A')) areaId = `A${areaId}`;
    if (!zoneId.startsWith('Z')) zoneId = `Z${zoneId}`;
    if (!lineId.startsWith('EQL')) lineId = `EQL${lineId}`;
    return [floorId, areaId, zoneId, lineId];
}

// export function textColor(color): string {
//     const hex = color.replace('#', '');
//     const colorRed = parseInt(hex.substring(0, 0 + 2), 16);
//     const colorGreen = parseInt(hex.substring(2, 2 + 2), 16);
//     const colorBlue = parseInt(hex.substring(4, 4 + 2), 16);
//     const brightness = (colorRed * 299 + colorGreen * 587 + colorBlue * 114) / 1000;
//     return brightness > 175 ? '#000000' : '#FFFFFF';
// }

export function findVariableToShow(allVariablesToShow: Array<string>, notification: VariableNotification): string {
    return allVariablesToShow.find(v => {
        const [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = v.split('.');
        return (notification.floorId === floorId &&
            notification.areaId === areaId &&
            notification.zoneId === zoneId &&
            notification.lineId === lineId &&
            //notification.equipmentType === equipmentType &&
            notification.equipmentId === equipmentId &&
            notification.variableType.toLowerCase() === variableType.toLowerCase() &&
            notification.variableName === variableName)
    })
}
export const memoizedFindVariableToShow = memoize(findVariableToShow);
export const clearMemoizedFindVariableToShow = () => memoizedFindVariableToShow.clear();

export function findVariableColor(areaId: string, zoneId: string, floorId: string, lineId: string, equipmentId: string, equipmentType: string, variableType: string, variableName: string, variableColorsConfiguration: VariableColors[]): VariableColors {
    //first find the whole fqn, then with wildcards
    return variableColorsConfiguration.find((setting) => {
        const [
            settingAreaId,
            settingZoneId,
            settingFloorId,
            settingLineId,
            settingEquipmentId,
            settingEquipmentType,
            settingVariableType,
            settingVariableName,
        ] = setting.variable.split('.');

        return (
            areaId === settingAreaId &&
            zoneId === settingZoneId &&
            floorId === settingFloorId &&
            lineId === settingLineId &&
            equipmentId === settingEquipmentId &&
            // settingEquipmentType === equipmentType &&
            variableType === settingVariableType &&
            settingVariableName === variableName
        )
            ||
            (
                [areaId, WILD_CARD_CHARACTER].includes(settingAreaId) &&
                [zoneId, WILD_CARD_CHARACTER].includes(settingZoneId) &&
                [floorId, WILD_CARD_CHARACTER].includes(settingFloorId) &&
                [lineId, WILD_CARD_CHARACTER].includes(settingLineId) &&
                [equipmentId, WILD_CARD_CHARACTER].includes(settingEquipmentId) &&
                // settingEquipmentType === equipmentType &&
                [variableType, WILD_CARD_CHARACTER].includes(settingVariableType) &&
                settingVariableName === variableName
            );
    });
}
export const memoizedFindVariableColor = memoize(findVariableColor); //, { maxAge: 60000 });
export const clearMemoizedFindVariableColor = () => memoizedFindVariableColor.clear();

export function getVariablesFilter(conditions: {
    requestId: string,
    warehouseId: string,
    variables: string[],
    maxNotificationItems: number,
    orderColumn: string,
    orderType: string,
}) {
    const { requestId, warehouseId, variables, maxNotificationItems, orderColumn, orderType } = conditions;
    if (!variables || variables.length === 0) return;
    const filters: VariableFilter[] = variables.map((variable) => {
        let [areaId, zoneId, floorId, lineId, equipmentId, equipmentType, variableType, variableName] = variable?.split('.');
        variableType = variableType === 'FAILURE' ? 'ALARM' : variableType;
        return {
            warehouseId,
            floorId,
            areaId,
            zoneId,
            lineId,
            equipmentId,
            //equipmentType, //in DT this property is *
            variableType,
            variableName,
        };
    });
    return new VariableFilters({
        requestId,
        filters,
        maxNotificationItems,
        orderColumn,
        orderType,
    });
}