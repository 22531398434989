import { inject } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { HomeService } from '@home/shared/home.service';
import { AppLoadService } from '@app/app-load.service';
import { ResolveFn } from '@angular/router';

export const HomeResolver: ResolveFn<any> = (): Observable<any> => {
    const homeService = inject(HomeService);
    const appLoadService = inject(AppLoadService);

    return forkJoin([
        homeService.getStructure().pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema al recuperar la estructura');
                return of(null);
            }),
        ),
        appLoadService.getCurrentWarehouse.pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema al recuperar el almacen actual', err);
                return of(null);
            }),
        ),
        appLoadService.getCurrentConfiguration.pipe(
            take(1),
            catchError((err) => {
                console.log('Ha ocurrido un problema al recuperar la configuracion', err);
                return of(null);
            }),
        ),
    ]).pipe(
        map((x) => {
            return {
                structure: x[0],
                warehouse: x[1],
                configuration: x[2],
            };
        }),
    );
}