import { inject, Injectable } from '@angular/core';
import { HttpResponse, HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, mergeMap, take } from 'rxjs/operators';
import { AppLoadService } from '@app/app-load.service';
import { environment } from '@environments/environment';
import { IdbService, IDB_STORES } from '../shared/cache/idb.service';
import { SettingsService } from '../shared/settings/settings.service';
import dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class CachingInterceptor implements HttpInterceptor {
    warehouse = null;

    private readonly appLoadService = inject(AppLoadService);
    private readonly settingsService = inject(SettingsService);
    private readonly idbService = inject(IdbService);

    constructor() {
        this.appLoadService.getCurrentWarehouse.pipe(take(1)).subscribe((res) => {
            this.warehouse = res.warehouse;
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // tslint:disable-next-line: max-line-length
        const cacheableUrls = [
            `${this.settingsService.getBaseUrl()}/auwas/warehouses`,
            `/map/${this.warehouse}`,
            `/map/${this.warehouse}/lines`,
            `/map/${this.warehouse}/equipments`,
        ];
        const cacheablePattern = `/map/${this.warehouse}/definitions/floors/`;
        const currentUrl = req.url.replace(environment.baseUrl, '');
        const isACacheableUrl = cacheableUrls.reduce((acc: boolean, cacheableUrl: string) => {
            return acc || (currentUrl.includes(cacheableUrl) && !currentUrl.includes('?equipmentType='));
        }, false);
        return this.idbService.get$(IDB_STORES.HTTP, currentUrl).pipe(
            mergeMap((cachedResponse: CachedResponse) => {
                const now = dayjs();
                const isNotCacheable = !(isACacheableUrl || currentUrl.includes(cacheablePattern));
                const isAlreadyCached = cachedResponse && now.isBefore(cachedResponse.expiryDate);
                return isNotCacheable
                    ? next.handle(req)
                    : isAlreadyCached
                        ? of(new HttpResponse({ body: cachedResponse.response }))
                        : this.sendRequest(req, next);
            }),
        );
    }

    sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    const entry = {
                        expiryDate: dayjs().add(1, 'hours').valueOf(),
                        response: event.body,
                        url: req.url.replace(environment.baseUrl, ''),
                    };
                    this.idbService.put$(IDB_STORES.HTTP, entry);
                }
            }),
        );
    }
}

interface CachedResponse {
    expiryDate: number;
    response: HttpResponse<any>;
    url: string;
}
