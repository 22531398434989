import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppLoadService } from '@app/app-load.service';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AlertArchiveParameters, VaribleArchiveParameters } from './archives';
import dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class ArchivesService {
    baseUrl: string;
    warehouse: string;

    private readonly http = inject(HttpClient);
    private readonly appLoadService = inject(AppLoadService);

    constructor() { }

    getArchivesRemoteStorage(): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const endpoint = `archives/${this.warehouse}/reportList`;
                return this.http.get<any>(`${this.baseUrl}/${endpoint}`);
            }),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al recuperar los archivos remotos', err);
                return of(null);
            }),
        );
    }

    generateAlertsArchive({
        floorId,
        areaId,
        zoneId,
        lineId,
        from = dayjs().subtract(1, 'days'),
        to = dayjs(),
        priority,
        description,
        equipmentId,
        equipmentTypeFilter,
    }: AlertArchiveParameters): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                const utcFrom = dayjs(from.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                const utcTo = dayjs(to.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                /* eslint-disable */
                let historicQuery = lineId  ? `alerts/${this.warehouse}/historyreport/floors/${floorId}/areas/${areaId}/zones/${zoneId}/lines/${lineId}` :
                                    zoneId  ? `alerts/${this.warehouse}/historyreport/floors/${floorId}/areas/${areaId}/zones/${zoneId}` :
                                    areaId  ? `alerts/${this.warehouse}/historyreport/floors/${floorId}/areas/${areaId}` :
                                    floorId ? `alerts/${this.warehouse}/historyreport/floors/${floorId}`
                                            : `alerts/${this.warehouse}/historyreport`;
                /* eslint-enable */
                historicQuery += `?from=${utcFrom}&to=${utcTo}`;
                if (priority) {
                    historicQuery += `&priority=${priority}`;
                }
                if (description) {
                    historicQuery += `&description=${description}`;
                }
                if (equipmentId) {
                    historicQuery += `&equipmentId=${equipmentId}`;
                }
                if (equipmentTypeFilter) {
                    historicQuery += `&equipmentTypeFilter=${equipmentTypeFilter}`;
                }

                let headers = new HttpHeaders();
                headers = headers.set('TimeZone-Offset', `${new Date().getTimezoneOffset()}`);
                const options: any = { headers, responseType: 'text' };
                return this.http.post<any>(`${this.baseUrl}/${historicQuery}`, null, options);
            }),
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al crear el excel del histórico de alertas para una linea', err);
                return of(null);
            }),
        );
    }

    generateVariablesArchive({
        from = '',
        to = '',
        floorid = '',
        areaid = '',
        zoneid = '',
        lineid = '',
        equipmentId = '',
        equipmentType = '',
        fqnPattern = '',
        variable = '',
        variableType = '',
        condition = null,
        orderColumn = 'floor',
        orderType = 'desc',
    }: VaribleArchiveParameters): Observable<any> {
        return this.appLoadService.getCurrentWarehouse.pipe(
            switchMap((res) => {
                this.baseUrl = `${res.hostName}/api`.toLowerCase();
                this.warehouse = res.warehouse;
                /* eslint-disable */
                let params = new HttpParams().set('orderColumn', orderColumn).set('orderType', orderType);
                const endpoint = zoneid
                    ? `variables/${this.warehouse}/history/download/floors/${floorid}/areas/${areaid}/zones/${zoneid}`
                    : areaid
                        ? `variables/${this.warehouse}/history/download/floors/${floorid}/areas/${areaid}`
                        : floorid
                            ? `variables/${this.warehouse}/history/download/floors/${floorid}`
                            : `variables/${this.warehouse}/history/download`;
                /* eslint-enable */
                if (from) {
                    const utcFrom = dayjs(from.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    params = params.set('from', utcFrom);
                }
                if (to) {
                    const utcTo = dayjs(to.toString()).subtract(dayjs().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss');
                    params = params.set('to', utcTo);
                }
                if (lineid) {
                    params = params.set('lineid', lineid);
                }
                if (equipmentId) {
                    params = params.set('equipmentId', equipmentId);
                }
                if (equipmentType) {
                    params = params.set('equipmentType', equipmentType);
                }
                if (variable) {
                    params = params.set('variableName', variable);
                }
                if (variableType) {
                    params = params.set('variableType', variableType.toLowerCase());
                }
                if (fqnPattern) {
                    params = params.set('fqnPattern', fqnPattern);
                }

                let headers = new HttpHeaders();
                headers = headers.set('TimeZone-Offset', `${new Date().getTimezoneOffset()}`);
                const options: any = {
                    headers,
                    params,
                    responseType: 'text',
                };
                return this.http.post<any>(`${this.baseUrl}/${endpoint}`, { condition }, options);
            }),
            take(1),
            catchError((err) => {
                console.error('Ha ocurrido un problema en el service al crear el excel del histórico de alertas para una linea', err);
                return of(null);
            }),
        );
    }
}
