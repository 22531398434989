/* eslint-disable @typescript-eslint/naming-convention */
import { inject, Injectable } from '@angular/core';
import { HubConnection, SignalRHubService } from '@notifications/signalr-hub.service';
import { HubConnectionState } from '@microsoft/signalr';
import { Subject } from 'rxjs';

export enum SignalRServices {
    WAREHOUSE_STATUS = 'WarehouseStatus',
    WAREHOUSE_VARIABLE_STATUS = 'WarehouseVariableStatus',
    ACTIVE_ALERT_COUNT = 'ActiveAlertsCount',
    REPORT_STATUS = 'ReportStatus',
    SERVICE_STATUS = 'ServiceStatus',
    CANONICAL_MAP = 'CanonicalMap',
};

export enum SignalREvents {
        ACTIVE_ALERTS_COUNT_CHANGED = 'ActiveAlertsCountChanged',
        REPORT_COUNT_CHANGED = 'ReportCountChanged',
        CANONICAL_MAP_CHANGED = 'CanonicalMapUpdated',
        WAREHOUSE_STATUS_VARIABLE_CHANGED = 'WarehouseStatusVariableChanged',
        ALERT_STATE_CHANGED = 'AlertStateChanged',
        LINE_STATE_CHANGED = 'LineStateChanged',
        EQUIPMENT_STATE_CHANGED = 'EquipmentStateChanged',
};

interface SignalRConnection {
    [signalRService: string]: {
        connection: HubConnection,
    }
};

@Injectable({ providedIn: 'root' })
export class SignalRStatusClient {
    sockets: SignalRConnection;
    onReconnect = new Subject<void>();

    private readonly hubService = inject(SignalRHubService);

    constructor() { }

    public connectToHub(
        service: SignalRServices,
        onDisconnected?: () => Promise<void>,
        onReconnecting?: () => Promise<void>,
        onAcquireLockFailed?: () => Promise<void>) {
        try {
            this.sockets = {
                ...this.sockets || {},
                ...{
                    [service]: {
                        connection: null,
                    }
                },
            }
            return new Promise((resolve, reject) => {
                try {
                    this.hubService
                        .connect(service, this.handleReconnection.bind(this, service), onDisconnected, onReconnecting, onAcquireLockFailed/*, token*/)
                        .then((connection) =>  this.sockets[service].connection = connection)
                        .then(resolve);
                } catch (error) {
                    console.error(error);
                    reject(error);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    public disconnectFromHub(service: SignalRServices) {
        try {
            this.hubService.disconnect(this.sockets[service].connection);
            this.onReconnect.complete();
            this.onReconnect = null;
        } catch (error) {
            console.error(error);
        }
    }

    public subscribe$(
        service: SignalRServices,
        groupName: string,
        supportedEvents: string[],
        notifyEvent: (groupName: string, eventName: string, notificationGroup: any[]) => void,
    ): Promise<void> {
        const that = this;
        try {
            return new Promise((resolve) => {
                if (this.isConnected(service)) {

                } 
                // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                (function subscribe$() {
                    try {
                        if (that.sockets[service].connection) {
                            return resolve(that.hubService.subscribe$(that.sockets[service].connection, groupName, supportedEvents, notifyEvent));
                        } else {
                            return new Promise((res, rej) => setTimeout(res, 5000)).then(subscribe$);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                })();
            });
        } catch (error) {
            console.error(error);
        }
    }

    public async unsubscribe$(service: SignalRServices, groupName: string, supportedEvents: string[]) {
        try {
            await this.hubService.unsubscribe$(this.sockets[service].connection, groupName, supportedEvents);
        } catch (error) {
            console.error(error);
        }
    }

    private async handleReconnection(service: SignalRServices) {
        try {
            this.onReconnect.next();
        } catch (error) {
            console.error(error);
        }
    }

    isConnected(service: SignalRServices) {
        return this.sockets[service].connection?.hubConnection.state === HubConnectionState.Connected;
    }

    getSignalRService(eventName: SignalREvents) {
        const serviceName = {
            [SignalREvents.ALERT_STATE_CHANGED]: SignalRServices.WAREHOUSE_STATUS,
            [SignalREvents.EQUIPMENT_STATE_CHANGED]: SignalRServices.WAREHOUSE_STATUS,
            [SignalREvents.LINE_STATE_CHANGED]: SignalRServices.WAREHOUSE_STATUS,
            [SignalREvents.WAREHOUSE_STATUS_VARIABLE_CHANGED]: SignalRServices.WAREHOUSE_VARIABLE_STATUS,
            [SignalREvents.ACTIVE_ALERTS_COUNT_CHANGED]: SignalRServices.ACTIVE_ALERT_COUNT,
            [SignalREvents.REPORT_COUNT_CHANGED]: SignalRServices.REPORT_STATUS,
            [SignalREvents.CANONICAL_MAP_CHANGED]: SignalRServices.SERVICE_STATUS,
        }[eventName];
        if (!serviceName) throw new Error(`Unable to find service for event ${eventName}`);
        // console.log(`SignalRService ${serviceName} for ${eventName}`);
        return serviceName;
    }
}
